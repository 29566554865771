import { localStorageKeys } from "../constants/general";
import { PagedRequest } from "../types/SharedTypes";

export const validateLink = (value: string): string|false => {
  const url = new URL(value);
  if (url.protocol !== 'https:') return false;
  
  const params = value.split('/').slice(3).join('/');
  return url.protocol.toLowerCase() + '//' + url.hostname.toLowerCase() + '/' + params;
};

export const sortListByPosition = <T extends {position: number},>(list: T[], ascending?: boolean): T[] => {
  if (ascending) return [...list].sort((a,b) => a.position > b.position ? 1 : -1);
  return [...list].sort((a,b) => a.position < b.position ? 1 : -1);
}

export const setLocalPref = (key: string, value: any) => {
  const currentPrefs = window.localStorage.getItem(localStorageKeys.localPreferences);
  let newPrefs: {[key: string]: any} = {};
  
  if (currentPrefs) newPrefs = JSON.parse(currentPrefs);
  newPrefs[key] = value;
  window.localStorage.setItem(localStorageKeys.localPreferences, JSON.stringify(newPrefs));
}
export const getLocalPref = (key: string) => {
  const currentPrefs = window.localStorage.getItem(localStorageKeys.localPreferences);
  if (!currentPrefs) return undefined;
  return JSON.parse(currentPrefs)[key];
}

export const formatSecureLink = (value: string) => {
  let addSecureVal: string;

  //handle adding/removing https:// automatically
  if (value.length === 1) addSecureVal = 'https://' + value;
  else if (value === 'https:/') addSecureVal = '';
  else addSecureVal = value;
  
  return addSecureVal;
}

export const createPagedRequestQueries = (params: PagedRequest) => {
  const fromId = params.fromId !== undefined ? `&fromId=${params.fromId}` : '';
  const limitOlder = params.limitOlder !== undefined ? `&limitOlder=${params.limitOlder}` : '';
  const limitNewer = params.limitNewer !== undefined ? `&limitNewer=${params.limitNewer}` : '';
  const includeFrom = params.includeFrom !== undefined ? `&includeFrom=${params.includeFrom}` : '';

  return '/?' + fromId + limitOlder + limitNewer + includeFrom;
};

export const timeUntil = (then: string): string => {
  const now = new Date();
  const targetDate = new Date(then);
  const diff = targetDate.getTime() - now.getTime();
  
  if (diff <= 0) return "soon";

  const msInHour = 1000 * 60 * 60;
  const msInDay = msInHour * 24;

  const days = Math.ceil(diff / msInDay);
  const hours = Math.floor((diff % msInDay) / msInHour);

  if (days === 0 && hours < 1) return "soon";
  else if (days > 1) return `${days} days`;
  else return `${hours} hours`;
}

export const basicDateFormat = (date: string) => {
  return new Date(date).toLocaleDateString('en-US', {dateStyle: 'medium'});
}

export const makePlural = (count: number, term: string, special?: boolean) => {
  if (count === 1) return term;
  else if (special) return term.substring(0, term.length - 1) + 'ies';
  else return term + 's';
}

export const checkLinkAgainstWhitelist = (link: string, setConfirmVisible: (prev: boolean) => void) => {
  const url = new URL(link);

  const comparer2 = url.host.split('.').slice(-2).join('.');
  const comparer3 = url.host.split('.').slice(-3).join('.');

  if (linkWhitelist.includes(comparer2) || linkWhitelist.includes(comparer3)) window.open(link, '_blank');
  else setConfirmVisible(true);
}

const linkWhitelist = [
  'mangadex.org',
  'themoviedb.org',
  'spotify.com',
  'books.google.com',
  'peaklist.app'
]