import { useAuth0 } from "@auth0/auth0-react";
import React, {createContext, useEffect, useState} from "react";

type AuthContextValue = {
  authUserId: string | undefined
}

export const AuthContext = createContext<AuthContextValue>({
  authUserId: undefined
});

export const clearLocalAuth = () => {
  localStorage.removeItem("user");
  window.location.assign('/');
}

export const AuthContextProvider = ({children}: {children: React.ReactNode}) => {
  
  const {isAuthenticated, getIdTokenClaims} = useAuth0();
  
  const [authUserId, setAuthId] = useState();
  
  useEffect(() => {
    const get = async () => {
      const value = await getIdTokenClaims();
      if (!value) return;
      const userId = value["https://peaklist.app/mongoUserId"];
      setAuthId(userId);
    }
    isAuthenticated && get();
  }, [isAuthenticated, getIdTokenClaims]);
  
  
  return (
    <AuthContext.Provider value={{
      authUserId
    }}>
      {children}
    </AuthContext.Provider>
  )
}