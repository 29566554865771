import { useContext, useState } from "react";
import { CreatorListItem } from "./OnListTab";
import { CreatorListItemType, CreatorListType } from "../../types/SharedTypes";
import { CREATOR_PAGES, CreatorContext } from "../../contexts/CreatorContext";
import { DeleteIcon, EditIcon, HiddenIcon, HomeIcon, VisibleIcon } from "../../assets/icons";
import GeneralConfirmationModal from "../../components/shared/modals/GeneralConfirmationModal";
import Modal from "../../components/Modal";
import TextInput from "../../components/shared/inputs/TextInput";

const fallbackImage = "https://images.pexels.com/photos/15417676/pexels-photo-15417676.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2";
type ActiveId = string | null;

const OffListTab = ({offList}: {offList: CreatorListType["offList"]}) => {
    
  const [activeId, setActiveId] = useState<ActiveId>(null);
  
  return (
    <div className="list-table">
      {offList.length === 0 && <p className="secondary center subtitle-large">Nothing to show.</p>}
      {offList.map((item,i) =>
        <CreatorListItem
          key={item?._id || item?.localId}
          listItem={item}
          activeId={activeId}
          setActiveId={setActiveId}
          listItemIndex={i}
          controls={
            <ListItemControls item={item} collapse={() => setActiveId(null)} />
          }
          dragProps={undefined}
        />
      )}
    </div>
  )
}

type ListItemControlProps = {
  item: CreatorListItemType;
  collapse: () => void;
}

const ListItemControls = ({item, collapse}: ListItemControlProps) => {
  const {moveItemOn, removeItem} = useContext(CreatorContext);
  
  const [isMovingTo, setIsMovingTo] = useState<boolean|string>(false);
  const [isDeleting, setIsDeleting] = useState(false);
  
  const handleMoveOn = () => {
    moveItemOn(item?._id || item?.localId, typeof isMovingTo === 'string' ? Number(isMovingTo) : undefined);
  }
  const handleRemoveItem = () => {
    removeItem(item?._id || item?.localId, CREATOR_PAGES.off);
  }
  
  return (
    <>
      {isDeleting && 
        <GeneralConfirmationModal setModalVisible={setIsDeleting} onConfirm={handleRemoveItem} confirmText={"Delete Item"} />
      }
      {isMovingTo !== false &&
        <MoveItemToSpotModal isMovingTo={isMovingTo} setIsMovingTo={setIsMovingTo} handleConfirm={handleMoveOn} />
      }
      <div className="item-row xl">
        <button className="icon-text-vert" onClick={handleMoveOn}>
          <EditIcon />
          <span className="subtitle-small weight-light">Edit</span>
        </button>
        <button className="icon-text-vert" onClick={handleMoveOn}>
          <VisibleIcon />
          <span className="subtitle-small weight-light">Move On</span>
        </button>
        <button className="icon-text-vert" onClick={() => setIsMovingTo(true)}>
          <VisibleIcon />
          <span className="subtitle-small weight-light">Move To #</span>
        </button>
        <button className="icon-text-vert" onClick={() => setIsDeleting(true)}>
          <DeleteIcon />
          <span className="subtitle-small weight-light">Delete</span>
        </button>
      </div>
    </>
  )
}

type MoveItemModalProps = {
  isMovingTo: boolean | string;
  setIsMovingTo: (prev: boolean|string) => void;
  handleConfirm: () => void;
}

export const MoveItemToSpotModal = ({isMovingTo, setIsMovingTo, handleConfirm}: MoveItemModalProps) => {
  return (
    <Modal controls setModalVisible={setIsMovingTo} bottomPane>
      <div className="right-item-layout center-horizontal">
        <TextInput
          isNumber
          onChange={(val) => setIsMovingTo(val)}
          id={'new-position-index'}
          value={isMovingTo === true ? undefined : `${isMovingTo}`}
          placeholder="New Position"
        />
        <button className="primary" onClick={handleConfirm}>
          Move
        </button>
      </div>
    </Modal>
  )
}

export default OffListTab;