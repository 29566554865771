import React, {useEffect, useState} from 'react';
import {OnChangeValue, PropsValue, SingleValueProps} from 'react-select';
import Select from 'react-select';

export type StringSelectOption = {
  label: string;
  value: string;
};
export type NumberSelectOption = {
  label: string;
  value: number;
};

type SelectOption = StringSelectOption | NumberSelectOption;

type SingleSelectChangeValue<T extends SelectOption> = OnChangeValue<T, false>;

export type SingleStringSelectChangeValue = SingleSelectChangeValue<StringSelectOption>;
export type SingleNumberSelectChangeValue = SingleSelectChangeValue<NumberSelectOption>;

export type MutliSelectValue = OnChangeValue<SelectOption, true>;

type SelectInputProps<T extends SelectOption> = {
  placeholder?: string;
  options: T[];
  onChange: (val: SingleSelectChangeValue<T>) => void;
  value: PropsValue<T>;
  required?: boolean;
  error?: boolean;
  defaultValueIndex?: number;
  isSearchable?: boolean;
  openToTop?: boolean;
};

//make a new NumberInput component

const Dropdown = <T extends SelectOption>({
  placeholder,
  options,
  onChange,
  value,
  required,
  error,
  defaultValueIndex = 0,
  isSearchable,
  openToTop,
}: SelectInputProps<T>) => {
  const [isError, setIsError] = useState<boolean>(false);

  //selects with default values should never have errors but if they do at some point then this will be ready
  useEffect(() => {
    if (!error) return;
    setIsError(true);
  }, [error]);

  const handleChange = (val: SingleSelectChangeValue<T>) => {
    onChange(val);
    setIsError(false);
  };

  //set default value as first in list
  // useEffect(() => {
  //   onChange(options[defaultValueIndex]);
  // }, []);

  return (
    <Select
      placeholder={placeholder}
      isMulti={false}
      isSearchable={isSearchable}
      classNamePrefix="select-"
      options={options}
      onChange={handleChange}
      value={value}
      className={isError ? 'select-error select-container' : 'select-container'}
      menuPlacement={openToTop ? 'top' : 'auto'}
    />
  );
};
export default Dropdown;
