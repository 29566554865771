import { useContext, useState } from 'react'
import Modal from '../Modal';
import { DraftIcon, PublishIcon, PublishPersonalIcon, DeleteIcon } from '../../assets/icons';
import { CreateCollectionForm } from '../createList/CreateListModal';
import CreateCollectionTab from '../createList/CreateCollectionTab';
import { DecoratedCollection } from '../../types/SharedTypes';
import { AuthContext } from '../../contexts/AuthContext';
import useHandleDeleteList from '../../hooks/useHandleDeleteList';
import { DeleteListModal } from '../shared/cards/ListFeedCard';
import useHandleUpdateCollection from '../../hooks/useHandleUpdateCollection';
import GeneralConfirmationModal from '../shared/modals/GeneralConfirmationModal';

type Props = {
  setModalVisible: (prev: boolean) => void;
  collection: DecoratedCollection;
}

const EditCollectionModal = ({setModalVisible, collection}: Props) => {
  
  const {authUserId} = useContext(AuthContext);
  const {handleConfirmDelete, handleRequestDelete, isConfirmingDelete, handleCancelDelete} = useHandleDeleteList();
  const {updateCollection, isLoading, updateCollectionsInSet} = useHandleUpdateCollection(() => setModalVisible(false));
  
  const [batchVisibilityModalVisible, setBatchVisibilityModalVisible] = useState<string>();
  const [collectionForm, setCollectionForm] = useState<CreateCollectionForm>({
    title: collection.title,
    description: collection.description,
    category: collection.category,
    subCategories: collection.subCategories || [],
    isPublic: collection.isPublic,
    isSmart: !!collection.smartCollection,
    period: collection.smartCollection?.period || 'weekly',
    startingKey: collection.smartCollection?.startingKey || 1,
    isSmartDisabled: collection.smartCollection?.isDisabled || false,
    image: collection.image
  });
  
  const [newImage, setNewImage] = useState<File>();
  
  const isCollectionValid = !!(collectionForm.title && collectionForm.description && collectionForm.category);
  
  const handleUpdateCollection = () => {
    if (!isCollectionValid) return;
    updateCollection(collectionForm, collection, newImage);
  }
  
  const handleUpdateVisibility = async () => {
    if (!collection.smartCollection) return;
    updateCollectionsInSet({isDraft: batchVisibilityModalVisible === 'draft'}, collection.smartCollection._id);
    setBatchVisibilityModalVisible(undefined);
  }
  const cancelBatchVis = () => {
    setBatchVisibilityModalVisible(undefined);
  }
  
  return (
    <>
      {authUserId && isConfirmingDelete &&
        <DeleteListModal
          handleConfirm={() => handleConfirmDelete(collection._id, true)}
          handleCancel={handleCancelDelete}
          title={collection.title}
          image={collection?.image}
        />
      }
      {batchVisibilityModalVisible &&
        <GeneralConfirmationModal
          setModalVisible={cancelBatchVis}
          onConfirm={handleUpdateVisibility}
          alert
          isLoading={isLoading}
        >
          <div className="item-list">
            <p className='subtitle-large'>{"This will update all collections in this smart collection and cannot be undone."}</p>
            {batchVisibilityModalVisible === 'publish' && 
              <p className='subtitle-large secondary'>{"Collections with no published lists will not be updated."}</p>
            }
          </div>
        </GeneralConfirmationModal>
      }
      <Modal scroll setModalVisible={setModalVisible} isLoading={isLoading}>
        <div className="item-list no-gap">
          <div className='right-item-layout padding padding-bottom-small center-horizontal sticky'>
            <h1 className="heading-medium">Edit Collection Settings</h1>
            <div className='item-row'>
              <button className='secondary short' onClick={() => setModalVisible(false)}>
                Cancel
              </button>
              <button className='primary short outline' onClick={handleUpdateCollection}>
                Save
              </button>
            </div>
          </div>
          <CreateCollectionTab isSmart={!!collection.smartCollection} isEdit form={collectionForm} setForm={setCollectionForm} setNewImage={setNewImage} collectionId={collection._id} />
          <div className='item-list large top-border padding'>
            <p className='subtitle-large weight-bold'>Actions</p>
            <div className='item-list'>
              {collection.smartCollection &&
                <>
                  <button className='icon-text subtle small' onClick={() => setBatchVisibilityModalVisible('draft')}>
                    <DraftIcon />
                    Revert Entire Collection to Draft
                  </button>
                  <button className='icon-text subtle small' onClick={() => setBatchVisibilityModalVisible('publish')}>
                    {collection.isPublic ? <PublishIcon /> : <PublishPersonalIcon />}
                    Publish Entire Collection as {collection.isPublic ? "Public" : "Personal"}
                  </button>
                </>
              }
              <button className='icon-text alert small' onClick={handleRequestDelete}>
                <DeleteIcon />
                Delete Collection {collection.smartCollection ? `(${collection.smartCollection.periodsCount} in set)` : ''}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default EditCollectionModal;
