import { Dispatch, SetStateAction, useState } from "react";
import TextInput from "../shared/inputs/TextInput";
import Dropdown, { SingleStringSelectChangeValue } from "../shared/inputs/Dropdown";
import { categories } from "../../assets/categories";
import { CreateCollectionForm } from "./CreateListModal";
import SwitchOptionButton from "../shared/inputs/SwitchOptionButton";
import { InfoIcon, UploadImageIcon } from "../../assets/icons";
import { fallbackImage } from "../../constants/urls";
import usePreviewImage from "../../hooks/usePreviewImage";
import { Categories, CollectionPeriods } from "../../types/SharedTypes";
import { collectionPeriods, getMaxKeyForPeriod } from "../../assets/general";
import { useGetCollectionById } from "../../api/data-access";

type Props = {
  form: CreateCollectionForm;
  setForm: Dispatch<SetStateAction<CreateCollectionForm>>;
  setNewImage: (file?: File) => void;
  isEdit?: boolean;
  isSmart?: boolean;
  collectionId?: string;
}

const CreateCollectionTab = ({form, setForm, setNewImage, isEdit, collectionId, isSmart}: Props) => {

  const currentCategory = categories.find(opt => opt.value === form.category) || null;
  const {clearImage, handleImage, imagePreview} = usePreviewImage(setNewImage);
  
  const updateCategory = (data: SingleStringSelectChangeValue) => {
    if (!data) return;

    setForm((prev) => ({
      ...prev,
      category: data.value as Categories,
    }));
  };
  
  return (
    <section className="padding">
      <div className='item-list large'>
        {!isEdit || isSmart &&
          <div className="item-row tile padding-small center-vertical" data-small={true}>
            <div className="icon-display secondary small">
              <InfoIcon />
            </div>
            <p className="subtitle-large spaced">
              {!isSmart
                ? <>A collection is a <span className="weight-bold">'list of lists'</span>. It can hold multiple lists, each of which have their own items.</> 
                : 'Changes to these settings will effect all collections in this smart collection set.'
              }
            </p>
          </div>
        }
        <div className="left-item-layout large">
          <div className="item-list">
            <p className="subtitle-medium indent">Thumbnail</p>
            <div className="item-row">        
              <label className='file-input' htmlFor='profileImage'>
                <div className='floating-indicator'>
                  <UploadImageIcon />
                </div>
                <div className='thumb large'>
                  <img src={imagePreview || form.image || fallbackImage} />
                </div>
              </label>
              <input id={"profileImage"} className='file-input' type='file' accept='image/*' onChange={(e) => e.target.files && handleImage(e.target.files[0])} />
            </div>
            {imagePreview && 
              <button className="subtle tiny" onClick={clearImage}>
                Remove
              </button>
            }
          </div>
          <div className="item-list">
            <TextInput
              value={form.title}
              onChange={(val) => setForm(prev => ({...prev, title: val}))}
              label='title'
              id='titleinput'
              required
            />
            <TextInput
              value={form.description}
              onChange={(val) => setForm(prev => ({...prev, description: val}))}
              label='description'
              id='descriptioninput'
              required
            />
          </div>
        </div>
        
        <SwitchOptionButton
          label={"Visibility"}
          required
          options={[
            {
              text: "Public",
              onClick: () => setForm(prev => ({...prev, isPublic: true})),
              selected: form.isPublic
            },
            {
              text: "Personal",
              onClick: () => setForm(prev => ({...prev, isPublic: false})),
              selected: !form.isPublic
            }
          ]} 
        />
        <div className="item-list med">
          <label aria-required={true} className="subtitle-medium indent">{"Category"}</label>
          <Dropdown
            value={currentCategory}
            options={categories}
            onChange={updateCategory}
            error={false}
            placeholder={'Select category...'}
            openToTop
            isSearchable={false}
          />
        </div>
        <SmartCollectionForm form={form} isEdit={isEdit} setForm={setForm} collectionId={collectionId} />
      </div>
    </section>
  )
}

type SmartCollectionFormProps = {
  form: CreateCollectionForm;
  setForm: Dispatch<SetStateAction<CreateCollectionForm>>;
  isEdit?: boolean;
  collectionId?: string;
}

const SmartCollectionForm = ({form, isEdit, setForm, collectionId}: SmartCollectionFormProps) => {
  
  const collection = useGetCollectionById(collectionId || '', !!collectionId);
  
  const [localStartingKey, setLocalStartingKey] = useState((form.startingKey || 1).toString());
  const [showSmartInfo, setShowSmartInfo] = useState(false);
  
  const currentPeriod = collectionPeriods.find(opt => opt.value === form.period) || null;
  const isSmartCollection = !!collection.data?.smartCollection;
  
  const updatePeriod = (data: SingleStringSelectChangeValue) => {
    if (!data) return;

    setForm((prev) => ({
      ...prev,
      period: data.value as CollectionPeriods,
    }));
  };
  
  const handleUpdateStartingKey = (val: string) => {
    const value = Number(val);
    if (val === '') {
      setLocalStartingKey(val);
      return;
    }
    if (Object.is(value, NaN) || value === 0) return;
    
    const maxValue = getMaxKeyForPeriod(form.period);
    
    if (value > maxValue) return;
    
    setLocalStartingKey(value.toString());
    setForm((prev) => ({
      ...prev,
      startingKey: value
    }));
  }
  
  return (
    <>
      <div className="item-row">
        {isEdit && isSmartCollection ?
          <p className="subtitle-large weight-bold">Smart Collection</p>
        :
          <div className="item-row">
            <input
              checked={form.isSmart}
              type={"checkbox"}
              id={'isSmart'}
              onChange={(e) => setForm(prev => ({...prev, isSmart: e.target.checked}))}
            />
            <label htmlFor={'isSmart'} className={'body'}>Smart Collection</label>
          </div>
        }
        <button className={'icon small secondary'} onClick={() => setShowSmartInfo(!showSmartInfo)}><InfoIcon /></button>
      </div>
      {showSmartInfo &&
        <div className="tile padding-small item-list" data-small={true}>
          <p className="subtitle-small secondary">Smart Collections</p>
          <p className="body spaced"><span className="weight-bold">Smart collections are a set of periodically created collections.</span> For example, if the period is "weekly", a new collection will be automatically created in the set every week. This set will appear as one single collection in your lists, with the addition of a timeline to navigate the created periods.</p>
        </div>
      }
      {form.isSmart && 
        <>
          <div className="item-list med">
            <label aria-required={true} className="subtitle-medium indent">{"Period"}</label>
            <Dropdown
              value={currentPeriod}
              options={collectionPeriods}
              onChange={updatePeriod}
              error={false}
              placeholder={'Select period...'}
              openToTop
              isSearchable={false}
            />
          </div>
          {form.period !== "seasonal" && 
            <TextInput
              value={localStartingKey}
              onChange={handleUpdateStartingKey}
              label={`starting date (1 - ${getMaxKeyForPeriod(form.period)})`}
              id='startingkeyinput'
              required
              note={form.period === 'weekly'
                ? `Example: '2' would create a new collection every Tuesday`
                : form.period === 'monthly'
                ? `Example: '14' would create a new collection on the 14th of every month.`
                : form.period === 'yearly'
                ? `Example: '284' would create a new collection on October 11th of each year.`
                : undefined
              }
            />
          }
        </>
      }
      {isEdit && isSmartCollection && 
        <div className="item-list">
          <div className="item-row">
            <input
              checked={form.isSmartDisabled}
              type={"checkbox"}
              id={'smartDisabled'}
              onChange={(e) => setForm(prev => ({...prev, isSmartDisabled: e.target.checked}))}
            />
            <label htmlFor={'smartDisabled'} className={'body'}>Disable Smart Collection</label>
          </div>
          <p className="subtitle-small weight-light secondary">Stops periods from being automatically created.</p>
        </div>
      }
    </>
  )
}

export default CreateCollectionTab;