import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TabItem } from "../../types/SharedTypes";

type TabBarProps = {
  tabData: TabItem[];
  unstyled?: boolean;
  floatRightElement?: React.ReactNode;
  sticky?: boolean;
}

const TabBar = ({
  tabData,
  unstyled,
  floatRightElement,
  sticky
}: TabBarProps) => {
  
  const [currentTab, setCurrentTab] = useState(0);
  
  useEffect(() => {
    //default the selected tab to the current pathname
    const path = window.location.pathname;
    for (let i = 0; i < tabData.length; i++) {
      const tab = tabData[i];
      if (tab.link && path.includes(tab.link)) {
        setCurrentTab(i);
      }
    }
  }, []);
  
  const tabStyle = (index: number) => {
    if (index === currentTab) {
      return "heading-small"
    }
    else return "heading-small secondary weight-light"
  }
  
  return (
    <nav 
      aria-label={"secondary"} 
      className={`tabs ${unstyled ? "" : "bottom-border border-secondary"}`} 
      data-unstyled={unstyled} 
      data-right-element={!!floatRightElement}
      data-sticky={sticky}
    >
      <div className="right-item-layout">
        <ul className="item-row large">
          {tabData.map((t,i) => 
            <React.Fragment key={i}>
              {t?.link && <WithLink setCurrentTab={setCurrentTab} tab={t} key={i} className={tabStyle(i)} i={i} />}
              {t?.onClick && <WithClick setCurrentTab={setCurrentTab} tab={t} key={i} className={tabStyle(i)} i={i} />}
            </React.Fragment>
          )}
        </ul>
        {floatRightElement && floatRightElement}
      </div>
    </nav>
  )
}

type WithLinkProps = {
  setCurrentTab: (i: number) => void;
  className: string;
  i: number;
  tab: {
    title: string;
    link: string;
  };
}

const WithLink = ({setCurrentTab, tab, className, i}: WithLinkProps) => {
  return (
    <Link to={tab.link} onClick={() => setCurrentTab(i)}>
      <li>
        <h2 className={className}>{tab.title}</h2>
      </li>
    </Link>
  )
}

type WithClickProps = {
  setCurrentTab: (i: number) => void;
  className: string;
  i: number;
  tab: {
    title: string;
    onClick: () => void;
  };
}

const WithClick = ({setCurrentTab, tab, className, i}: WithClickProps) => {
  
  const handleClick = () => {
    tab.onClick();
    setCurrentTab(i);
  }
  
  return (
    <a onClick={handleClick}>
      <li>
        <h2 className={className}>{tab.title}</h2>
      </li>
    </a>
  )
}

export default TabBar;