import { baseAPIUrl } from "../constants/urls";
import { Media } from "../types/SharedTypes";
import createAxios from "./authInterceptor";

const mediaUrl = `${baseAPIUrl}/media`;

const mediaApi = createAxios;

export const createNewMedia = async (media: FormData) => {
  const res = await mediaApi.post<Media>(mediaUrl + "/create", media, {headers: {'Content-Type': 'multipart/form-data'}});
  return res.data;
}