import { useGetUserPublicPosts } from "../../api/data-access";
import LoadingIcon from "../../components/shared/LoadingIcon";
import FeedCard from "../../components/shared/cards/FeedCard";

const PublicTab = () => {
  
  const {data, isSuccess} = useGetUserPublicPosts();
  
  if (!isSuccess) return (
    <div className="item-row center-horizontal padding">
      <LoadingIcon />
    </div>
  )
  return (
    <section data-padding-small={true}>
      <div className="item-list">
        {data.map((l) => 
          <FeedCard key={l._id} post={l} small returnRecentInSmartSet />
        )}
        {data.length === 0 && 
          <p className="subtitle-large secondary center">
            Nothing to show
          </p>
        }
      </div>
    </section>
  )
}
export default PublicTab;