import { useSpecialThemed } from "../../../hooks/useThemed";
import { PopulatedList, BaseListType } from "../../../types/SharedTypes";
import ContextMenu, { ContextMenuContent } from "../modals/ContextMenu";
import { DraftIcon, HiddenIcon, PinIcon, PinIconSolid, PublishIcon, PublishPersonalIcon, ReportIcon, DeleteIcon, VisibleIcon } from "../../../assets/icons";
import { MouseEvent, useContext, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import usePinToProfile from "../../../hooks/usePinToProfile";
import FeedCardBody from "./FeedCardBody";
import { useGetListById, useRevertPostToDraft, useUpdatePostVisibility } from "../../../api/data-access";
import useHandleDeleteList from "../../../hooks/useHandleDeleteList";
import GeneralConfirmationModal from "../modals/GeneralConfirmationModal";
import { fallbackImage } from "../../../constants/urls";
import useDisplayServerError from "../../../hooks/useDisplayServerError";
import { makePlural } from "../../../tools/Helpers";

type ListFeedCardProps = {
  badgeBanner?: React.ReactNode;
  hideHeader?: boolean;
  small?: boolean;
  isPinned?: boolean;
  isInFeed?: boolean;
  themedHeader?: boolean;
  link?: string;
  preventLink?: boolean;
  preventOptions?: boolean;
  handleClick?: () => void;
  forceTheme?: string;
} & ({
  list: PopulatedList | BaseListType;
  listId?: never;
} | {
  listId: string;
  list?: never;
});

const ListFeedCard = ({list: _list, listId, badgeBanner, hideHeader, small, link, preventLink, isPinned, themedHeader, handleClick, isInFeed, forceTheme}: ListFeedCardProps) => {
  
  const {authUserId} = useContext(AuthContext);
  const {data: fetchedList, isError, isLoading} = useGetListById(listId || "", !!listId);
  
  const list = _list || fetchedList;
  const themeOverride = useSpecialThemed(forceTheme || list?.theme);
  const {updatePin, removePin} = usePinToProfile();
  const updateVisibility = useUpdatePostVisibility();
  const revertPostToDraft = useRevertPostToDraft();
  const {handleConfirmDelete, handleRequestDelete, isConfirmingDelete, handleCancelDelete} = useHandleDeleteList();
  const {handleError} = useDisplayServerError();
  
  const thumbImage = list?.image;
  
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  
  const handleVisibility = () => {
    if (!list) return;
    updateVisibility.mutate({id: list._id, update: list.isPublic ? 'personal' : 'public'}, {
      onError: handleError
    });
  }
  
  const handleDraft = () => {
    if (!list) return;
    revertPostToDraft.mutate({id: list._id}, {
      onError: handleError
    });
  }
  
  const isAuth = list?.author && typeof list?.author !== "string" ? authUserId === list?.author._id : authUserId === list?.author;
  const contextMenuContent: ContextMenuContent = {
    focusedItem: {
      image: list?.image || null,
      title: `${list?.title}`,
      subtitle: "List"
    },
    title: `Options`,
    menuContent: [
      {
        title: 'Pin to Profile',
        icon: PinIconSolid,
        visible: !!list && !isPinned && list.isPublic && !list.isDraft,
        handleClick: () => list && updatePin(list._id)
      },
      {
        title: 'Remove Pin',
        icon: PinIcon,
        visible: (!!list && !!isPinned),
        handleClick: () => removePin()
      },
      {
        title: "Report",
        disabled: true,
        visible: !isAuth,
        handleClick: () => null,
        icon: ReportIcon
      },
      {
        title: `Change to ${list?.isPublic ? "Personal" : "Public"}`,
        visible: isAuth && !!list && !list.parentCollection && !list.isDraft,
        handleClick: handleVisibility,
        icon: list?.isPublic ? PublishPersonalIcon : PublishIcon
      },
      {
        title: `Revert to Draft`,
        visible: isAuth && !!list && !list.isDraft,
        handleClick: handleDraft,
        icon: DraftIcon
      },
      {
        title: "Delete List",
        disabled: false,
        visible: isAuth,
        handleClick: handleRequestDelete,
        icon: DeleteIcon,
        alert: true
      }
    ]
  };
  
  const handleContextMenu = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setContextMenuVisible(true);
  }
  
  if (isLoading) return <div data-small={small} className="skeleton-card" />;
  else if (isError && listId) return <p>Error</p>
  else if (!list) return null;
  return (
    <>
      {authUserId && isConfirmingDelete && 
        <DeleteListModal handleConfirm={() => list && handleConfirmDelete(list._id)} handleCancel={handleCancelDelete} title={list.title} image={thumbImage} />
      }
      {authUserId && contextMenuVisible && 
        <ContextMenu setModalVisible={setContextMenuVisible} menuData={contextMenuContent} />
      }
      <FeedCardBody
        type={'List'}
        handleContextMenu={handleContextMenu}
        hideHeader={hideHeader}
        badgeBanner={badgeBanner}
        small={small}
        isPinned={isPinned}
        isInFeed={isInFeed}
        themedHeader={themedHeader}
        themeOverride={themeOverride}
        author={list.author}
        isDraft={list.isDraft}
        createdAt={list.createdAt}
        thumbImage={thumbImage}
        link={link || `/list/${list._id}?theme=${list.theme || 'default'}`}
        preventLink={preventLink}
        handleClick={handleClick}
        entriesTerm={makePlural(list.entriesCount, 'Item')}
        entriesLength={list.entriesCount}
        title={list.title}
        category={list.category}
      />
    </>
  )
}

type DeleteModalProps = {
  handleCancel: () => void;
  handleConfirm: () => void;
  title: string;
  image?: string;
}

export const DeleteListModal = ({handleCancel, handleConfirm, image, title}: DeleteModalProps) => {
  
  const [isLoading, setIsLoading] = useState(false);
  
  const onConfirm = () => {
    setIsLoading(true);
    handleConfirm();
  }
  
  return (
    <GeneralConfirmationModal
      confirmText="Delete Forever"
      setModalVisible={handleCancel}
      onConfirm={onConfirm}
      alert
      isLoading={isLoading}
    >
      <div className="item-row">
        <img className="thumb small" src={image || fallbackImage} />
        <p className="heading-small">{title}</p>
      </div>
    </GeneralConfirmationModal>
  )
}

export default ListFeedCard;