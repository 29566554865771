type PageWrapperProps = {
  children: React.ReactNode;
  scrollBumper?: boolean;
  padding?: boolean;
  preventOutline?: boolean;
  classNames?: string;
  overrideClassNames?: boolean;
  fixedHeight?: boolean;
}

const PageWrapper = ({children, padding = true, scrollBumper = true, preventOutline, classNames, overrideClassNames, fixedHeight}: PageWrapperProps) => {
  
  const className = overrideClassNames ? classNames || '' : `item-list no-gap ${classNames || ''}`;
  
  return (
    <main data-padding={padding} data-nav={scrollBumper} data-outline={false} className={className} data-fixed-height={fixedHeight}>
      {children}
    </main>
  )
}
export default PageWrapper;