import { Dispatch, SetStateAction, useEffect, useState} from "react";
import TextInput from "../shared/inputs/TextInput";
import { CloseIcon, UploadImageIcon } from "../../assets/icons";
import Dropdown, { SingleStringSelectChangeValue } from "../shared/inputs/Dropdown";
import { CreateListForm } from "./CreateListModal";
import { categories } from "../../assets/categories";
import SwitchOptionButton from "../shared/inputs/SwitchOptionButton";
import { fallbackImage } from "../../constants/urls";
import usePreviewImage from "../../hooks/usePreviewImage";
import { Categories } from "../../types/SharedTypes";

type Props = {
  form: CreateListForm;
  setForm: Dispatch<SetStateAction<CreateListForm>>;
  setNewImage: (file?: File) => void;
  forCollection?: string;
}

const CreateListTab = ({form, setForm, setNewImage, forCollection}: Props) => {
  
  const [moreOptionsVisible, setMoreOptionsVisible] = useState(false);
  const {clearImage, handleImage, imagePreview} = usePreviewImage(setNewImage);
  
  const currentCategory = categories.find(opt => opt.value === form.category) || null;
  
  const updateCategory = (data: SingleStringSelectChangeValue) => {
    if (!data) return;

    setForm((prev) => ({
      ...prev,
      category: data.value as Categories,
    }));
  };
  
  return (
    <section className="padding">
      <div className='item-list large'>
        <TextInput
          value={form.title}
          onChange={(val) => setForm(prev => ({...prev, title: val}))}
          label='title'
          id='titleinput'
          required
        />
        <TextInput 
          value={form.description}
          onChange={(val) => setForm(prev => ({...prev, description: val}))}
          label='description'
          id='descriptioninput'
          required
        />
        <div className="item-list">
          <div className="item-row">
            <p className="subtitle-medium indent">Thumbnail</p>
            {imagePreview && 
              <button className="subtle icon-text tiny xxs" onClick={clearImage}>
                Remove Image
                <CloseIcon />
              </button>
            }
          </div>
          <div className="item-row xl">
            <div>        
              <label className='file-input' htmlFor='profileImage'>
                <div className='floating-indicator'>
                  <UploadImageIcon />
                </div>
                <div className='thumb large'>
                  <img src={imagePreview || form.image || fallbackImage} />
                </div>
              </label>
              <input id={"profileImage"} className='file-input' type='file' accept='image/*' onChange={(e) => e.target.files && handleImage(e.target.files[0])} />
            </div>
            <div className="divider-line-vertical or" />
            <TextInput 
              value={form.thumbnailEntry ? `${form.thumbnailEntry}` : ''}
              placeholder={"Default 3"}
              onChange={(val) => setForm(prev => ({...prev, thumbnailEntry: Number(val)}))}
              label='Use image from list item in position'
              id='thumbEntryinput'
            />
          </div>
        </div>
        {!forCollection &&
          <div className="item-list med">
            <label aria-required={true} className="subtitle-medium indent">{"Category"}</label>
            <Dropdown 
              value={currentCategory}
              options={categories}
              onChange={updateCategory}
              error={false}
              placeholder={'Select category...'}
              openToTop
              isSearchable={false}
            />
          </div>
        }
        {!forCollection &&
          <SwitchOptionButton
            label={"Visibility"}
            required
            options={[
              {
                text: "Public",
                onClick: () => setForm(prev => ({...prev, isPublic: true})),
                selected: form.isPublic
              },
              {
                text: "Personal",
                onClick: () => setForm(prev => ({...prev, isPublic: false})),
                selected: !form.isPublic
              }
            ]} 
          />
        }
        <SwitchOptionButton
          label={"List Type"}
          required
          note={form.ordered 
            ? "Items in ordered lists are numbered."
            : "Items in unordered lists are not numbered, but do retain the order you set."
          }
          options={[
            {
              text: "Ordered",
              onClick: () => setForm(prev => ({...prev, ordered: true})),
              selected: form.ordered
            },
            {
              text: "Unordered",
              onClick: () => setForm(prev => ({...prev, ordered: false})),
              selected: !form.ordered
            }
          ]} 
        />
        <SwitchOptionButton
          disabled={!form.ordered}
          label={"Order"}
          required
          note={form.ascending 
            ? "Ascending puts the 1st place item at the top." 
            : "Descending puts the 1st place item at the bottom."
          }
          options={[
            {
              text: "Ascending",
              onClick: () => setForm(prev => ({...prev, ascending: true})),
              selected: form.ascending
            },
            {
              text: "Descending",
              onClick: () => setForm(prev => ({...prev, ascending: false})),
              selected: !form.ascending
            }
          ]} 
        />
        {/* <button className="text item-row large" onClick={() => setMoreOptionsVisible(!moreOptionsVisible)}>
          More Options
          <div className="icon small">
            {moreOptionsVisible ? <ArrowHeadUpIcon /> : <ArrowHeadDownIcon />}
          </div>
        </button>
        {moreOptionsVisible &&
          <p>stuff</p>
        } */}
      </div>
    </section>
  )
}

export default CreateListTab;