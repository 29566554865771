import { useNavigate, useParams } from "react-router";
import { AddIcon, CheckIcon, CloseIcon, DeleteIcon, DraftIcon, FlaggedIcon, HiddenIcon, ImportIcon, MinusIcon, PublishIcon, PublishPersonalIcon, RecommendIcon, SettingsIcon, UploadImageIcon } from "../assets/icons";
import TabBar from "../components/navigation/TabBar";
import useFullCustomTheme from "../hooks/useFullCustomTheme";
import useHideHeader from "../hooks/useHideHeader";
import PageWrapper from "./PageWrapper";
import { AutoPopulateListItem, Categories, CreatorListItemPayload, CreatorListItemType, ListVisibilityKey, TabItem } from "../types/SharedTypes";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import OnListTab from "./ListCreator/OnListTab";
import OffListTab from "./ListCreator/OffListTab";
import TextInput from "../components/shared/inputs/TextInput";
import { CREATOR_PAGES, CreatorContext, CreatorContextProvider } from "../contexts/CreatorContext";
import Modal from "../components/Modal";
import { ToastContext } from "../contexts/ToastContext";
import themes from "../assets/themes";
import ThemeIndicator from "../components/createList/ThemeIndicator";
import { useCreateMedia } from "../api/data-access/generalHooks";
import ContextMenu, { ContextMenuContent } from "../components/shared/modals/ContextMenu";
import EditListModal from "../components/listCreator/EditListModal";
import { getCategory } from "../assets/categories";
import { formatSecureLink } from "../tools/Helpers";
import LoadingContainer from "../components/shared/LoadingContainer";
import ImportListItemsModal from "../components/listCreator/ImportListItemsModal";
import { DragDropContext, DragUpdate, DropResult } from "react-beautiful-dnd";
import useDisablePullToRefresh from "../hooks/useDisablePullToRefresh";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import AutoPopulateItemModal from "../components/shared/modals/AutoPopulateItemModal";
import usePreviewImage from "../hooks/usePreviewImage";
import { fallbackImage } from "../constants/urls";

type ListCreatorProps = {
  setNavActive: Dispatch<SetStateAction<boolean>>;
}

export type DragPosition = {
  destination: number,
  source: number
} | null;

const ListCreatorStudio = ({setNavActive}: ListCreatorProps) => {
  
  const {id} = useParams();
  const navigate = useNavigate();
  const {creatorState, getList, listInfoState, isListPending, isListSuccess, currentTab, setCurrentTab, list, setListInfoState, reorderItem, moveManyItemsOff} = useContext(CreatorContext);
  
  useEffect(() => {
    getList(id);
  }, [id]);

  const [currentShift, setCurrentShift] = useState<DragPosition>(null);
  const [themeModalVisible, setThemeModalVisible] = useState(false);
  const [settingsModalVisible, setSettingsModalVisible] = useState(false);
  const [unplacedVisible, setUnplacedVisible] = useState(false);
  const [isDraggingUnplaced, setIsDraggingUnplaced] = useState(false);
  
  const tabs: TabItem[] = [
    {title: "On List", onClick: () => setCurrentTab("on")},
    {title: "Off List", onClick: () => setCurrentTab("off")}
  ];
  
  useDocumentTitle('Editor')
  useHideHeader();
  useDisablePullToRefresh();
  useFullCustomTheme(listInfoState?.theme, true);
  useEffect(() => {
    setNavActive(false);
    return () => {
      setNavActive(true);
    }
  }, []);
  
  useEffect(() => {
    if (creatorState.unplaced.length === 0) setUnplacedVisible(false);
    else setUnplacedVisible(true);
  }, [creatorState.unplaced.length]);
  
  const onDragUpdate = (update: DragUpdate) => {
    const {source, destination} = update;
    console.log(destination?.droppableId);
    setIsDraggingUnplaced(destination?.droppableId === 'on-list');

    if (!destination || (source.droppableId === 'unplaced' && destination.droppableId === 'unplaced')) return;
    setCurrentShift({
      destination: destination.index, 
      source: source.droppableId === 'unplaced' ? creatorState.on.length + 1 : source.index
    });
  }
  
  const onDragEnd = (result: DropResult) => {
    const {source, destination} = result;
    setIsDraggingUnplaced(false);
    
    setCurrentShift(null);
    
    if (!destination || 
      (destination.index === source.index && source.droppableId !== 'unplaced') || //position is the same and not from unplaced
      (source.droppableId === 'unplaced' && destination.droppableId === 'unplaced') //from and to is unplaced
    ) return;
    
    reorderItem(result.draggableId, destination.index, destination.droppableId !== source.droppableId);
  }
  
  const handleMoveUnplacedOff = () => {
    moveManyItemsOff(creatorState.unplaced.map(item => item._id || item.localId || ''));
    setUnplacedVisible(false);
  }
  
  if (isListPending) return <LoadingContainer />;
  else if (!isListSuccess || !listInfoState) return <p>Error</p>
  return (
    <>
      {themeModalVisible && 
        <ThemeModal 
          setModalVisible={setThemeModalVisible} 
          updateTheme={(theme) => setListInfoState(prev => {
            if (!prev) return prev;
            return {...prev, theme}
          })}
          currentThemeSlug={listInfoState.theme}
        />
      }
      {settingsModalVisible && <EditListModal setModalVisible={setSettingsModalVisible} />}
      <PageWrapper padding={false} classNames={unplacedVisible ? 'split-view' : ''} overrideClassNames={unplacedVisible}>
        <div className="item-list" data-padding={true}>
          <div className="right-item-layout">
            <div className="item-row">
              <div className="tag">
                <p className="subtitle-large">{getCategory(listInfoState.category)}</p>
              </div>
            </div>
            <div className="item-row">
              <button className="text" onClick={() => setThemeModalVisible(true)}>
                <ThemeIndicator themeSlug={listInfoState.theme} />
              </button>
              <button className="icon" onClick={() => setSettingsModalVisible(true)}>
                <SettingsIcon />
              </button>
            </div>
          </div>
          {list && <p className="subtitle-medium secondary">{new Date(list?.createdAt).toDateString()}</p>}
          <h1 className="heading-medium">{listInfoState.title}</h1>
        </div>
        <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
          <div className="item-list" data-scroll={unplacedVisible} data-lock-scroll={unplacedVisible && isDraggingUnplaced}>
            <TabBar tabData={tabs} sticky />
            {currentTab === "on" && <OnListTab currentShift={currentShift} onList={creatorState.on} />}
            {currentTab === "off" && <OffListTab offList={creatorState.off} />}
          </div>
          {unplacedVisible &&
            <div className="padding-xs-horizontal unplaced-view">
              <div className="tile outline overlay full-height item-list no-gap">
                <div className="padding-xs bottom-border right-item-layout no-gap center-horizontal">
                  <p className="heading-small padding-small-horizontal">Unplaced</p>
                  <button 
                    className="secondary icon-text small short escape-padding" 
                    onClick={handleMoveUnplacedOff}
                    aria-disabled={creatorState.unplaced.length === 0}
                  >
                    <HiddenIcon />
                    Move all Off
                  </button>
                </div>
                <OnListTab dropId={'unplaced'} isDragging={isDraggingUnplaced} currentShift={currentShift} onList={creatorState.unplaced} />
              </div>
            </div>
          }
        </DragDropContext>
        <ControlBar setUnplacedVisible={setUnplacedVisible} />
      </PageWrapper>
    </>
  )
}

const ControlBar = (props: {setUnplacedVisible: Dispatch<SetStateAction<boolean>>}) => {
  
  const [isCreating, setIsCreating] = useState(false);
  
  return (
    <>
      {isCreating && 
        <ListItemCreatorControls handleClose={() => setIsCreating(false)} />
      }
      <nav 
        className="bottom-floating" 
        aria-label={"creator navigation"}
      >
        <CreatorControls setIsCreating={setIsCreating} setUnplacedVisible={props.setUnplacedVisible} />
      </nav>
    </>
  )
}

type CreatorControlsProps = {
  setUnplacedVisible: Dispatch<SetStateAction<boolean>>;
  setIsCreating: (val: boolean) => void;
}

const CreatorControls = ({setIsCreating, setUnplacedVisible}: CreatorControlsProps) => {
  
  const {updateList, listInfoState, creatorState, list, clearDraftFromLocal} = useContext(CreatorContext);
  const {requestToast} = useContext(ToastContext);
  const navigate = useNavigate();
  
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [importModalVisible, setImportModalVisible] = useState(false);
  
  const handleUpdate = (visibility: ListVisibilityKey, goToUserLists: string) => {
    updateList(
      visibility, 
      () => {
        requestToast("List updated.", "success");
        navigate(list?.parentCollection ? `/collection/${list.parentCollection}` : `/lists/${goToUserLists}`);
      }, 
      () => setUpdateModalVisible(false)
    );
  }
  
  const handleDiscard = () => {
    clearDraftFromLocal();
    navigate(-1);
  }
  
  const menuData: ContextMenuContent = {
    focusedItem: false,
    title: 'Save',
    menuContent: [
      {
        title: listInfoState?.isDraft ? 'Save Draft' : "Revert to Draft",
        handleClick: () => handleUpdate('draft', 'drafts'),
        promptLoading: true,
        icon: DraftIcon
      },
      {
        title: listInfoState?.isDraft 
          ? list?.parentCollection
            ? 'Publish to Collection'
            : `Publish as ${listInfoState?.isPublic ? "Public" : "Personal"}` 
          : 'Update List',
        handleClick: () => handleUpdate('publish', listInfoState?.isPublic ? "public" : "personal"),
        promptLoading: true,
        icon: listInfoState?.isPublic ? PublishIcon : PublishPersonalIcon,
        disabled: creatorState.on.length === 0
      }
    ]
  }
  
  const cancelMenuData: ContextMenuContent = {
    focusedItem: false,
    title: 'Discard',
    menuContent: [
      {
        title: "Discard Changes",
        handleClick: handleDiscard,
        icon: DeleteIcon
      }
    ]
  };
  
  return (
    <>
      {updateModalVisible && <ContextMenu setModalVisible={setUpdateModalVisible} menuData={menuData} />}
      {cancelModalVisible && <ContextMenu setModalVisible={setCancelModalVisible} menuData={cancelMenuData} />}
      {importModalVisible && <ImportListItemsModal setModalVisible={setImportModalVisible} />}
      
      <div className="main-nav-layout">
        <div className="item-row">
          <button aria-disabled={creatorState.unplaced.length === 0} className="icon nav" onClick={() => setUnplacedVisible(prev => !prev)}>
            {creatorState.unplaced.length > 0 && <span className="notification-badge">{creatorState.unplaced.length}</span>}
            <FlaggedIcon />
          </button>
          <button className="icon nav" onClick={() => setImportModalVisible(true)}>
            <ImportIcon />
          </button>
        </div>
        
        <div className="item-row center-horizontal">
          <button className="icon-text nav small primary" onClick={() => setIsCreating(true)}>
            <AddIcon />
            <p className="header-medium">Add Entry</p>
          </button>
        </div>
        
        <div className="item-row justify-end">
          <button className="icon background nav cancel" onClick={() => setCancelModalVisible(true)}>
            <CloseIcon />
          </button>
          <button className="icon background nav confirm" onClick={() => setUpdateModalVisible(true)}>
            <CheckIcon />
          </button>
        </div>
      </div>
    </>
  )
}

type ListItemCreatorControlsProps = {
  handleClose: () => void;
} & ({
  editItem?: never;
  page?: never;
} | {
  editItem: CreatorListItemType;
  page: CREATOR_PAGES;
});

type ListItemForm = {
  title: string;
  subtitle: string;
  link: string;
  notes: string;
  imageOverride?: string;
}

export const ListItemCreatorControls = ({handleClose, editItem, page}: ListItemCreatorControlsProps) => {
  
  const {createNewItem, updateItemInformation, currentTab, listInfoState} = useContext(CreatorContext);
  const {requestToast} = useContext(ToastContext);
  const createMedia = useCreateMedia();
  
  const [image, setImage] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<ListItemForm>({
    title: editItem?.title || "",
    subtitle: editItem?.subtitle || "",
    link: editItem?.link || "",
    notes: editItem?.authorNotes || "",
    imageOverride: editItem?.image
  });
  const [showSearchTMDBModal, setShowSearchTMDBModal] = useState(false);
  
  const {handleImage, clearImage, imagePreview} = usePreviewImage(setImage);
  
  const isValid = formData.title && formData.subtitle;
  
  const handleSelectItem = (item: AutoPopulateListItem) => {
    console.log(item);
    setFormData({
      title: item.title,
      subtitle: item.subtitle || '',
      link: item.link,
      notes: item.description,
      imageOverride: item.image || undefined
    })
  }
  
  const handleUpdate = async () => {
    if (!isValid || !editItem) return;
    setIsLoading(true);
    let imageLink: string | undefined;
    
    if (image) {
      const mediaData = new FormData();
      mediaData.append('file', image);
      
      await createMedia.mutateAsync(mediaData).then(res => {
        imageLink = res?.url
      }).catch(err => {
        requestToast("Image failed to upload.", "error");
        setIsLoading(false);
      });
    }
    
    const updatedItem: CreatorListItemType = {...editItem};
    updatedItem.title = formData.title;
    updatedItem.subtitle = formData.subtitle;
    updatedItem.link = formData.link;
    imageLink && (updatedItem.image = imageLink);
    updatedItem.authorNotes = formData.notes && formData.notes;
    
    updateItemInformation(updatedItem, page);
    setIsLoading(false);
    handleClose();
  }
  
  const handleCreate = async () => {
    if (!isValid) return;
    setIsLoading(true);
    let imageLink: string | undefined;
    
    if (image) {
      const mediaData = new FormData();
      mediaData.append('file', image);
      
      await createMedia.mutateAsync(mediaData).then(res => {
        imageLink = res?.url
      }).catch(err => requestToast("Image failed to upload.", "error"));
    }
    
    const newListItem: CreatorListItemPayload = {
      localId: `${formData.title}${Date.now()}`,
      title: formData.title,
      subtitle: formData.subtitle,
      image: formData.imageOverride || imageLink,
      link: formData.link,
      authorNotes: formData.notes && formData.notes,
      isPublic: currentTab === 'on'
    };
    
    createNewItem(newListItem);
    setIsLoading(false);
    handleClose();
  }
  
  return (
    <>
      {showSearchTMDBModal && <AutoPopulateItemModal setModalVisible={setShowSearchTMDBModal} onSelectItem={handleSelectItem} category={listInfoState?.category} /> }
      <Modal classNames="black" bottomPane isLoading={isLoading}>
        <div className="item-list full-height">
          <div className="item-list large">
            <SearchNoticeBanner setShowModal={setShowSearchTMDBModal} category={listInfoState?.category} />
            <div className="item-list">
              <p className="subtitle-medium indent">Required Info</p>
              <div className="item-list small">
                <div className="left-item-layout">
                  <div className='item-list'>
                    <label className='file-input full-width' htmlFor='entryFileInput'>
                      <div className='floating-indicator'>
                        <UploadImageIcon />
                      </div>
                      <div className='thumb large'>
                        <img src={formData.imageOverride || imagePreview || fallbackImage} />
                      </div>
                    </label>
                    {imagePreview && 
                      <button className="subtle icon-text tiny" onClick={clearImage}>
                        Remove
                      </button>
                    }
                  </div>
                  <input 
                    type="file" 
                    id={'entryFileInput'} 
                    className="file-input" 
                    accept="image/*" 
                    onChange={(e) => e.target.files && handleImage(e.target.files[0])} 
                  />
                  <div className="item-list small">
                    <TextInput
                      value={formData.title}
                      onChange={(val) => setFormData(prev => ({...prev, title: val}))}
                      placeholder={"Title"}
                      id='titleinput'
                      secondary
                    />
                    <TextInput
                      value={formData.subtitle}
                      onChange={(val) => setFormData(prev => ({...prev, subtitle: val}))}
                      placeholder={"Subtitle"}
                      id='subtitleinput'
                      secondary
                    />
                  </div>
                </div>
                
                <TextInput
                  value={formData.link}
                  onChange={(val) => setFormData(prev => ({...prev, link: formatSecureLink(val)}))}
                  placeholder='https://...'
                  label="Link"
                  id='linkinput'
                  secondary
                />
              </div>
            </div>
            <div className="item-list">
              <p className="subtitle-medium indent">More Info</p>
              <TextInput
                value={formData.notes}
                onChange={(val) => setFormData(prev => ({...prev, notes: val}))}
                placeholder={"Description/Notes"}
                id='notesinput'
                secondary
                textarea
              />
            </div>
          </div>
          <div className="item-row center-horizontal">
            <button className="icon-text small subtle" onClick={handleClose}>
              <MinusIcon />
              Cancel
            </button>
            <button className="icon-text small primary" aria-disabled={!isValid} onClick={editItem ? handleUpdate : handleCreate}>
              <CheckIcon />
              {editItem ? "Update" : "Create"}
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

const ListCreator = (props: ListCreatorProps) => {
  
  return (
    <CreatorContextProvider>
      <ListCreatorStudio {...props} />
    </CreatorContextProvider>
  )
}

type ThemeModalProps = {
  setModalVisible: (prev: boolean) => void;
  updateTheme: (slug: string) => void;
  currentThemeSlug: string | undefined;
  handleSave?: (slug: string) => void;
  isLoading?: boolean;
}

export const ThemeModal = ({setModalVisible, updateTheme, currentThemeSlug, handleSave, isLoading}: ThemeModalProps) => {
  
  const [stagedTheme, setStagedTheme] = useState(currentThemeSlug || 'default');
  
  const currentTheme = themes.find(theme => theme.slug === stagedTheme);
  
  const handleUpdateTheme = (theme: string) => {
    setStagedTheme(theme);
    updateTheme(theme);
  }
  
  const localHandleSave = () => {
    if (!handleSave) return;
    handleSave(stagedTheme);
  }
  
  return (
    <Modal scroll setModalVisible={setModalVisible} isLoading={isLoading}>
      <div className="item-list">
        <div className="sticky">
          <div className='right-item-layout padding padding-bottom-small padding-right-small center-horizontal'>
            <p className="heading-medium">Theme</p>
            {handleSave ? 
              <div className='item-row'>
                <button className='secondary short' onClick={() => setModalVisible(false)}>
                  Cancel
                </button>
                <button className='primary short outline' onClick={localHandleSave}>
                  Save
                </button>
              </div>
            :
              <button className='icon xs' onClick={() => setModalVisible(false)}>
                <CloseIcon />
              </button>
            }
          </div>
        </div>
        <div className="item-row padding">
          {currentTheme && <ThemeIndicator theme={currentTheme} />}
          <div className="item-list">
            <p className="subtitle-large">{"Current Theme"}</p>
            <p className="heading-small">{currentTheme?.title || "None set"}</p>
          </div>
        </div>
        
        <div className="item-list no-gap">
          {themes.map(theme =>
            <div key={theme.slug} className="bottom-border padding-small right-item-layout center-horizontal">
              <div className="item-row">
                <ThemeIndicator theme={theme} />
                <p className="heading-small">{theme.title}</p>
              </div>
              <button 
                aria-disabled={currentTheme?.slug === theme.slug}
                className="primary"
                onClick={() => handleUpdateTheme(theme.slug)}
              >
                {currentTheme?.slug === theme.slug ? 'Selected' : "Select"}
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

const SearchNoticeBanner = ({setShowModal, category}: {setShowModal: (s: boolean) => void, category?: Categories}) => {
  
  if (category !== 'movies' && category !== 'tvshows' && category !== 'anime' && category !== 'manga' && category !== 'books' && category !== 'music') return null;
  return (
    <div className="tile outline padding-small item-list">
      <div className="item-row">
        <RecommendIcon />
        <div className="item-list small">
          <p className="subtitle-medium">Auto Populate</p>
          <p className="body">Search for your item to input it faster!</p>
        </div>
      </div>
      <button className="primary" onClick={() => setShowModal(true)}>{`Search ${getCategory(category)}`}</button>
    </div>
  )
}

export default ListCreator;