import { Categories } from "../types/SharedTypes";

export type CategoryType = {
  value: Categories;
  label: string;
}

export const getCategory = (value: string, isAuto?: boolean) => {
  if (isAuto) return "Auto-Generated";
  return (categories.find(cat => cat.value === value))?.label || 'Unknown';
}

export const categories: CategoryType[] = [
  {value: 'music', label: "Music"},
  {value: 'movies', label: "Movies"},
  {value: 'tvshows', label: "TV Shows"},
  {value: 'anime', label: "Anime"},
  {value: 'manga', label: "Manga"},
  {value: 'books', label: "Books"},
  {value: 'people', label: "People"},
  {value: 'tech', label: "Consumer Tech"},
  {value: 'beauty', label: "Fashion & Beauty"},
  {value: 'food', label: "Food"},
  {value: 'vehicles', label: "Vehicles"},
  {value: 'furniture', label: "Furniture"},
  {value: 'places', label: "Places"},
  {value: 'design', label: "Design"},
  {value: 'websites', label: "Apps & Websites"},
  {value: 'other', label: "Other"}
]