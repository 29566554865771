import { useState } from 'react'
import Modal from '../Modal';
import { CheckIcon, InfoIcon, ReportIcon } from '../../assets/icons';
import { useGetListsToImportForCollection, useImportListsToCollection } from '../../api/data-access';
import LoadingIcon from '../shared/LoadingIcon';
import useDisplayServerError from '../../hooks/useDisplayServerError';

type Props = {
  setModalVisible: (prev: boolean) => void;
  collectionId: string;
}

const ImportListModal = ({setModalVisible, collectionId}: Props) => {
  
  const {data, isSuccess, isPending} = useGetListsToImportForCollection(collectionId);
  const importLists = useImportListsToCollection();
  const {handleError} = useDisplayServerError();
  
  const [selectedLists, setSelectedLists] = useState<string[]>([]);
  
  const handleImportList = () => {
    importLists.mutate({id: collectionId, lists: selectedLists}, {
      onError: handleError,
      onSuccess: () => setModalVisible(false)
    })
  }
  
  const toggleList = (newId: string) => {
    if (selectedLists.find(item => item === newId)) {
      setSelectedLists(prev => prev.filter(item => item !== newId));
    }
    else setSelectedLists(prev => [...prev, newId]);
  }
  
  return (
    <Modal 
      controls
      modalTitle='Import Lists'
      scroll 
      setModalVisible={setModalVisible} 
      fixedBottomItem 
      removePadding
      fixedBottomItemChildren={
        <div className='padding-small item-row'>
          <button className='primary full-width' aria-disabled={selectedLists.length === 0} onClick={handleImportList}>
            {selectedLists.length === 0 
              ? "No Lists Selected"
              : `Import ${selectedLists.length} List${selectedLists.length === 1 ? '' : "s"}`
            }
          </button>
        </div>
      }
    >
      {isPending || !isSuccess ? <LoadingIcon /> :
        <div className="item-list no-gap"> 
          <div className='item-row no-gap padding-small padding-large-left bottom-border'>
            <div className='icon-display padding-small-horizontal'>
              <ReportIcon />
            </div>
            <p className='body'>
              Imported lists are removed as standalone lists when brought into a collection.
            </p>
          </div>
          <div className='item-list padding-small'>
            {data.length === 0 && 
              <div className='item-list padding center-horizontal'>
                <p className='body secondary center'>No available lists.</p>
                <div className='item-row '>
                  <div className='icon-display secondary'>
                    <InfoIcon />
                  </div>
                  <p className='body secondary center'>Lists must be the same category as the collection to import.</p>
                </div>
              </div>
            }
            {data.map(list =>
              <button
                key={list._id}
                className='empty darkness-hover'
                onClick={() => toggleList(list._id)}
              >
                <div className="tile right-item-layout padding-small center-horizontal">
                  <div className="item-row">
                    {list.image &&
                      <div className='thumb'>
                        <img src={list.image} />
                      </div>
                    }
                    <div className='item-list'>
                      <p className="heading-medium weight-light">{list.title}</p>
                      <p className="subtitle-medium secondary">Entries: {list.entriesCount || "Unknown"}</p>
                    </div>
                  </div>
                  {selectedLists.includes(list._id) &&
                    <CheckIcon />
                  }
                </div>
              </button>
            )}
          </div>
        </div>
      }
    </Modal>
  )
}

export default ImportListModal;
