import { useContext, useEffect, useState } from "react";
import ToggleSwitch from "../components/shared/inputs/ToggleSwitch";
import { localPrefKeys } from "../constants/general";
import { getLocalPref, setLocalPref } from "../tools/Helpers";
import PageWrapper from "./PageWrapper";
import { useAuth0 } from "@auth0/auth0-react";
import { useGetAuthUser } from "../api/data-access";
import { ToastContext } from "../contexts/ToastContext";
import axios, { AxiosRequestConfig } from "axios";
import {baseAPIUrl} from "../constants/urls";
import { ArrowHeadLeftIcon } from "../assets/icons";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Modal";

const Settings = () => {
  
  const {requestToast} = useContext(ToastContext);
  const { logout, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  
  const {data: user} = useGetAuthUser();
  
  const [promptEvent, setPromptEvent] = useState<any>();
  const [downloadDataURL, setDownloadDataURL] = useState<string|undefined>(undefined);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  
  useEffect(() => {
    const beforeInstallPromptHandler = (event: any) => {
      event.preventDefault();
      setPromptEvent(event);
    };
    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    return () => window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
  }, []);
  
  const handleLogout = () => {
    logout({logoutParams: {returnTo: process.env.REACT_APP_HOME_URL}});
  };
  
  const handleExportData = async () => {
    try {
      requestToast("Fetching your data.");
      
      const token = await getAccessTokenSilently();
      const config: AxiosRequestConfig = {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        responseType: 'blob'
      }
      
      const res = await axios.put(`${baseAPIUrl}/exportdata/json`, undefined, config);
      
      const blob = new Blob([res.data], { type: 'application/json' });
      const localUrl = window.URL.createObjectURL(blob);
      setDownloadDataURL(localUrl);
      requestToast("Your data is ready to download!", 'success');
    }
    catch (err) {
      console.log(err);
      requestToast("Failed to fetch your data, please contact support.", 'error');
    }
  }
  
  return (
    <>
      {showDeleteModal &&
        <Modal 
          setModalVisible={setShowDeleteModal}
          modalTitle="Delete Account"
          controls
          classNames="small"
        >
          <div className="item-list">
            <p className="body spaced secondary">Please contact support to delete your account, from the email your account is associated with.</p>
            <p className="heading-medium weight-light">support@peaklist.app</p>
          </div>
        </Modal>
      }
      <PageWrapper padding={false}>
        <SettingSection>
          <div className="item-row">
            <a onClick={() => navigate(-1)} className="icon small">
              <ArrowHeadLeftIcon />
            </a>
            <h1 className="heading-large">Settings</h1>
          </div>
        </SettingSection>
        <SettingSection>
          <h2 className="heading-small">General</h2>
          <ToggleSwitch 
            handleChange={(checked) => setLocalPref(localPrefKeys.forceDarkTheme, checked)} 
            id={'forceDarkTheme'} 
            label="Force Dark Theme in Custom Themes"
            initialValue={getLocalPref(localPrefKeys.forceDarkTheme)}
          />
        </SettingSection>
        <SettingSection>
          <h2 className="heading-small">Editor</h2>
          <ToggleSwitch 
            handleChange={(checked) => setLocalPref(localPrefKeys.disableThemeInEditor, checked)} 
            id={'disableThemeEditorSwitch'} 
            label="Disable Custom Theme in Editor"
            initialValue={getLocalPref(localPrefKeys.disableThemeInEditor)}
          />
        </SettingSection>
        {promptEvent && 
          <SettingSection>
            <h2 className="heading-small">PWA</h2>
            <button className="primary" onClick={() => promptEvent.prompt()}>Install</button>
          </SettingSection>
        }
        <SettingSection>
          <h2 className="heading-small">Actions</h2>
          <button className="primary" onClick={handleLogout}>Logout</button>
          <p className="subtitle-large">Danger Zone</p>
          {/* <button className="primary alert" onClick={() => null}>Delete Account</button> */}
          {!downloadDataURL && <button className="primary" onClick={handleExportData}>Export List Data</button>}
          {downloadDataURL && 
            <a href={downloadDataURL} download={'your-peaklist-data.json'}>
              <button className="primary full-width success">Download JSON</button>
            </a>
          }
          <button className="primary" onClick={() => setShowDeleteModal(true)}>Delete Account</button>
        </SettingSection>
        <div className="padding">
          <p className="subtitle-medium secondary"><span className="weight-bold">UserID</span> {user?._id}</p>
        </div>
        
      </PageWrapper>
    </>
  )
}

const SettingSection = ({children}: {children: React.ReactNode}) => {
  return (
    <section className="item-list large bottom-border padding">
      {children}
    </section>
  )
}

export default Settings;