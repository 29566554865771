import { useNavigate, useParams } from "react-router";
import PageWrapper from "./PageWrapper";
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { DecoratedList, DecoratedListItem, ListItemType } from "../types/SharedTypes";
import { ArrowHeadLeftIcon, FeedIcon, LikeIcon, LikeIconSolid, LinkIcon, OptionsHorizontalIcon, SaveIcon, SaveIconSolid, DeleteIcon } from "../assets/icons";
import useFullCustomTheme from "../hooks/useFullCustomTheme";
import { ToastContext } from "../contexts/ToastContext";
import { checkLinkAgainstWhitelist, sortListByPosition } from "../tools/Helpers";
import { useCreateLike, useCreateSave, useRemoveLike, useRemoveSave } from "../api/data-access/generalHooks";
import { getCategory } from "../assets/categories";
import GeneralConfirmationModal from "../components/shared/modals/GeneralConfirmationModal";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { useGetListById, useGetListByIdOpen } from "../api/data-access";
import { UseQueryResult } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import ContextMenu, { ContextMenuContent } from "../components/shared/modals/ContextMenu";
import LightboxModal from "../components/shared/modals/LightboxModal";
import useDisplayServerError from "../hooks/useDisplayServerError";

export type ActiveIndex = number | null;

const List = ({auth = true}: {auth?: boolean}) => {

  //reset scroll to top when load
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return auth ? <AuthListContent /> : <NoAuthListContent />;
}

const NoAuthListContent = () => {
  const {id} = useParams();
  const list = useGetListByIdOpen(id || '');
  
  return <ListContent list={list} />
}

const AuthListContent = () => {
  const {id} = useParams();
  const list = useGetListById(id || '');
  
  return <ListContent list={list} />
}

type ListContentProps = {
  list: UseQueryResult<DecoratedList, Error>;
}

const ListContent = ({list}: ListContentProps) => {
  
  const [searchParams] = useSearchParams();
  
  const {isAuthenticated} = useAuth0();
  const {requestToast} = useContext(ToastContext);
  
  const [activeIndex, setActiveIndex] = useState<ActiveIndex>(null);
  
  useFullCustomTheme(list.data?.theme || searchParams.get('theme')?.toString());
  useDocumentTitle(list.data?.title || "List", list.isSuccess);
  
  const onList = useMemo(() => list.isSuccess && sortListByPosition<ListItemType | DecoratedListItem<ListItemType>>(list.data.onList, list.data.ascending), [list]);

  if (list.isPending || !onList) return null;
  else if (list.isError || !list.isSuccess) return <p>Error</p>;
  return (
    <PageWrapper padding={false}>
      <ListHeader list={list.data} preventLike={!isAuthenticated || list.data.isAuth} allowEdit={list.data.isAuth && !list.data.isAutoGenerated } />
      <div className="list-table">
        {onList.map((l, i) =>
          <ListItem
            key={l._id}
            preventSave={!isAuthenticated || list.data.isAuth}
            listItem={l}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            listItemIndex={i}
            ordered={list.data.ordered}
            ascending={list.data.ascending}
            isAutoGenerated={list.data.isAutoGenerated}
          /> 
        )}
      </div>
    </PageWrapper>
  )
}

type ListHeaderProps = {
  list: DecoratedList;
  preventLike: boolean;
  allowEdit: boolean;
}

const ListHeader = ({list, preventLike, allowEdit}: ListHeaderProps) => {
  
  const navigate = useNavigate();
  const [listLiked, setListLiked] = useState(false);
  
  const createLike = useCreateLike();
  const removeLike = useRemoveLike();
  
  useEffect(() => {
    setListLiked(!!list.likedByAuthUser);
  }, [list])
  
  const handleToggleLike = () => {
    if (listLiked) {
      removeLike.mutate(list._id);
      setListLiked(false);
    }
    else {
      createLike.mutate(list._id);
      setListLiked(true);
    }
  }
  
  return (
    <section className="item-list padding">
      <div className="right-item-layout">
        <div className="item-row">
          <a onClick={() => navigate(-1)} className="icon small">
            <ArrowHeadLeftIcon />
          </a>
          <div className="tag" data-themed={true}>
            <p className="subtitle-medium header">{getCategory(list.category)}</p>
          </div>
        </div>
        {!list.isAuth &&
          <Link to={`/user/${list.author.username}`}>
            <article className="item-row center-vertical card-header">
              <div className="item-list">
                <h3 className="subtitle-large">{list.author.displayName}</h3>
              </div>
              <div className="user-avatar small">
                <img src={list.author.picture} />
              </div>
            </article>
          </Link>
        }
        {allowEdit &&
          <Link to={`/creator/${list._id}`}>
            <button className="primary short">Edit</button>
          </Link>
        }
      </div>
      <div className="item-list small">
        <div className="item-row">
          <p className="subtitle-medium secondary">List</p>
          <p className="subtitle-medium">{new Date(list.createdAt).toLocaleDateString('en-US', {dateStyle: 'medium'})}</p>
        </div>
        <div className="item-row">
          <p className="subtitle-medium secondary">Views</p>
          <p className="subtitle-medium">{list.views || 0}</p>
        </div>
      </div>
      <div className="right-item-layout center-horizontal">
        <h1 className="heading-large">{list.title}</h1>
        {!preventLike &&
          <button className="icon" onClick={handleToggleLike}>
            {listLiked ? <LikeIconSolid /> : <LikeIcon />}
          </button>
        }
      </div>
      
    </section>
  )
}

type ListItemProps = {
  activeIndex: ActiveIndex;
  listItem: ListItemType | DecoratedListItem<ListItemType>;
  setActiveIndex: Dispatch<SetStateAction<ActiveIndex>>;
  listItemIndex: number;
  ordered?: boolean;
  ascending?: boolean;
  preventSave?: boolean;
  customControl?: React.ReactNode;
  isAutoGenerated?: boolean;
  noHero?: boolean;
};

export const ListItem = ({listItem, activeIndex, setActiveIndex, listItemIndex, ordered = true, ascending = true, preventSave, customControl, isAutoGenerated, noHero}: ListItemProps) => {
  
  const {requestToast} = useContext(ToastContext);
  const navigate = useNavigate();
  const {handleError} = useDisplayServerError();
  
  const [saved, setSaved] = useState('savedByAuthUser' in listItem ? listItem.savedByAuthUser : false);
  const [saveListItemOptionsMenuVisible, setSaveListItemOptionsMenuVisible] = useState(false);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  
  const saveItem = useCreateSave();
  const unsaveItem = useRemoveSave();
  
  const handleSave = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    
    if (saved) {
      unsaveItem.mutate(listItem._id, {
        onSuccess: () => {
          setSaved(false);
          requestToast('Removed from saved.', 'success');
        },
        onError: (err) => handleError(err)
      });
    }
    else {
      saveItem.mutate(listItem._id, {
        onSuccess: () => {
          setSaved(true);
          requestToast('Saved for later.', 'success');
        },
        onError: (err) => handleError(err)
      });
    }
    setSaved(prev => !prev);
  }
  
  const handleRemoveSavedItem = () => {
    if (!listItem.meta?.originalListItem) return;
    unsaveItem.mutate(listItem.meta?.originalListItem, {
      onSuccess: () => requestToast('Removed item.'),
      onError: () => requestToast('Failed to remove.', 'error')
    });
  }
  
  const savedItemMenuData: ContextMenuContent = {
    title: "Saved Item Options",
    focusedItem: false,
    menuContent: [
      {
        title: "Go to Original List",
        icon: FeedIcon,
        handleClick: () => navigate(`/list/${listItem.meta?.originalList}`),
        visible: !!listItem.meta?.originalList
      },
      {
        title: "Remove Save",
        icon: DeleteIcon,
        alert: true,
        handleClick: handleRemoveSavedItem
      }
    ]
  }
  
  return (
    <>
      {saveListItemOptionsMenuVisible && <ContextMenu menuData={savedItemMenuData} setModalVisible={setSaveListItemOptionsMenuVisible} />}
      {imageModalVisible &&
        <LightboxModal setModalVisible={setImageModalVisible}>
          <div className="item-list center-vertical full-height center-horizontal">
            <img className="pretty" src={listItem.image} />
            <p className="body indent secondary">{listItem.title}</p>
          </div>
        </LightboxModal>
      }
      <div 
        className={`table-item item-list large ${ascending ? 'bottom-border' : 'top-border' }`}
        data-hero={!noHero && ordered && listItem.position === 1}
        data-numbered={ordered}
        key={listItem._id}
        role={"button"}
        onClick={() => setActiveIndex(prev => prev === listItemIndex ? null : listItemIndex)}
      >
        {ordered && <p className="heading-small weight-light center">{listItem.position}</p>}
        <div className="item-list position-relative">
          {/* {ordered && <p className="heading-small weight-light center floating-position">{listItem.position}</p>} */}
          <div className="right-item-layout">
            <div className="item-row large">
              {listItem.image &&
                <button className="empty darkness-hover" onClick={(e) => {
                  e.stopPropagation();
                  setImageModalVisible(true);
                }}>
                  <div className="thumb large">
                    <img src={listItem.image} />
                  </div>
                </button>
              }
              <div className="item-list min-width">
                <p className="heading-small weight-light ellipsis-block">{listItem.title}</p>
                <p className="subtitle-medium ellipsis">{listItem.subtitle}</p>
              </div>
            </div>
            {customControl && customControl}
            {!customControl && !preventSave && activeIndex === listItemIndex && !isAutoGenerated &&
              <button className="icon-text-vert" onClick={handleSave}>
                {saved ? <SaveIconSolid /> : <SaveIcon />}
                <span className="subtitle-small weight-light">Save{+ saved ? "d" : ''}</span>
              </button>
            }
            {!customControl && activeIndex === listItemIndex && isAutoGenerated &&
              <button className="icon-text-vert" onClick={(e) => {
                e.stopPropagation();
                setSaveListItemOptionsMenuVisible(true);
              }}>
                <OptionsHorizontalIcon />
                <span className="subtitle-small weight-light">Options</span>
              </button>
            }
          </div>
        </div>
        <div className="table-item-info-container" aria-current={activeIndex === listItemIndex}>
          <div>
            <ListItemControls item={listItem} />
          </div>
        </div>
      </div>
    </>
  )
}

type ListItemControlsProps = {
  item: ListItemType;
}

const ListItemControls = ({item}: ListItemControlsProps) => {
  
  const [linkModalVisible, setLinkModalVisible] = useState(false);
  
  if (!item.link && !item.authorNotes) return <></>;
  return (
    <>
      {linkModalVisible &&
        <GeneralConfirmationModal setModalVisible={setLinkModalVisible} onConfirm={() => window.open(item.link, '_blank')}>
          <div className="item-list xl">
            <div className="item-list small">
              <p className="heading-small spaced">By clicking this link you'll be leaving Peaklist.</p>
              <p className="subtitle-large spaced secondary">We can't guarantee anything good will come out of this.</p>
            </div>
            <a href={item.link} rel="noopener" target="_blank" className="link">
              <p className="subtitle-large weight-bold spaced break-anywhere">{item.link}</p>
            </a>
          </div>
        </GeneralConfirmationModal>
      }
      <div className="item-list large table-controls min-width">
        {item.link && 
          <button className="text icon-text small" onClick={() => checkLinkAgainstWhitelist(item.link, setLinkModalVisible)}>
            <LinkIcon />
            <p className="subtitle-medium weight-bold ellipsis">{item.link}</p>
          </button>
        }
        {item.authorNotes &&
          <div className="item-list small">
            <p className="subtitle-small">Notes</p>
            <p className="subtitle-small weight-light spaced">{item.authorNotes}</p>
          </div>
        }
      </div>
    </>
  )
}

export default List;