import { useEffect, useState } from "react";
import Modal from "../../Modal";
import { useGetTMDBListItem, useSearchBooks, useSearchManga, useSearchMusic, useSearchTMDB } from "../../../api/data-access";
import LoadingIcon from "../LoadingIcon";
import SearchBar from "../SearchBar";
import { AutoPopulateListItem, AutoPopulateListItemResults, Categories, MovieResults, SearchTMDBPayload } from "../../../types/SharedTypes";
import { fallbackImage } from "../../../constants/urls";
import { searchAttributions } from "../../../constants/general";
import { FilterButtons } from "../../../pages/Dashboard";

type InnerProps = {
  onSelectItem: (id: AutoPopulateListItem) => void;
  category: Categories;
}

const SearchTMDB = ({onSelectItem, category}: InnerProps) => {
  
  const [searchQuery, setSearchQuery] = useState('');
  const [rawSearchQuery, setRawSearchQuery] = useState('');
  const [selectedItem, setSelectedItem] = useState<string>();
  //anime has its own searchIn but for now we use tv/movie
  const [searchIn, setSearchIn] = useState<SearchTMDBPayload['searchIn'] | null>(
    category === 'movies' ? 'movie' : 'tv'
  );
  
  const {data: results, isLoading, isSuccess, isError} = useSearchTMDB(
    {query: searchQuery || undefined, searchIn: searchIn || 'movie'},
    (searchQuery.length > 2 && !!searchIn)
  );
  
  const autoListItem = useGetTMDBListItem({id: selectedItem || '', searchIn: searchIn || 'movie'}, (!!selectedItem && !!searchIn));
  
  const handleSearch = () => {
    setSearchQuery(rawSearchQuery);
  }
  
  useEffect(() => {
    if (!autoListItem.isSuccess || !autoListItem.data) return;
    onSelectItem(autoListItem.data);
  }, [autoListItem.isSuccess]);

  return (
    <SearchResults<SearchTMDBPayload['searchIn']>
      results={results}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleSearch={handleSearch}
      query={rawSearchQuery}
      setQuery={setRawSearchQuery}
      setSelectedItem={setSelectedItem}
      category={category}
      filters={category === "anime" 
        ? [{value: 'tv', label: 'Shows'}, {value: 'movie', label: 'Movies'}] 
        : undefined
      }
      currentFilter={searchIn}
      setCurrentFilter={setSearchIn}
    />
  )
}

const SearchManga = ({onSelectItem, category}: InnerProps) => {
  
  const [searchQuery, setSearchQuery] = useState('');
  const [rawSearchQuery, setRawSearchQuery] = useState('');
  const [selectedItem, setSelectedItem] = useState<string>();
  
  const {data: results, isLoading, isSuccess, isError} = useSearchManga(
    {query: searchQuery || undefined},
    (searchQuery.length > 2)
  );
  
  const handleSearch = () => {
    setSearchQuery(rawSearchQuery);
  }
  
  useEffect(() => {
    if (!selectedItem || !isSuccess) return;
    const item = results.results.find(res => res.id === selectedItem);
    item && onSelectItem(item);
  }, [selectedItem]);

  return (
    <SearchResults
      results={results}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleSearch={handleSearch}
      query={rawSearchQuery}
      setQuery={setRawSearchQuery}
      setSelectedItem={setSelectedItem}
      category={category}
    />
  )
}

const SearchBooks = ({onSelectItem, category}: InnerProps) => {
  
  const [searchQuery, setSearchQuery] = useState('');
  const [rawSearchQuery, setRawSearchQuery] = useState('');
  const [selectedItem, setSelectedItem] = useState<string>();
  
  const {data: results, isLoading, isSuccess, isError} = useSearchBooks(
    {query: searchQuery || undefined},
    (searchQuery.length > 2)
  );
  
  const handleSearch = () => {
    setSearchQuery(rawSearchQuery);
  }
  
  useEffect(() => {
    if (!selectedItem || !isSuccess) return;
    const item = results.results.find(res => res.id === selectedItem);
    item && onSelectItem(item);
  }, [selectedItem]);

  return (
    <SearchResults
      results={results}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleSearch={handleSearch}
      query={rawSearchQuery}
      setQuery={setRawSearchQuery}
      setSelectedItem={setSelectedItem}
      category={category}
    />
  )
}

const SearchMusic = ({onSelectItem, category}: InnerProps) => {
  
  const [searchQuery, setSearchQuery] = useState('');
  const [rawSearchQuery, setRawSearchQuery] = useState('');
  const [selectedItem, setSelectedItem] = useState<string>();
  
  const {data: results, isLoading, isSuccess, isError} = useSearchMusic(
    {query: searchQuery || undefined},
    (searchQuery.length > 2)
  );
  
  const handleSearch = () => {
    setSearchQuery(rawSearchQuery);
  }
  
  useEffect(() => {
    if (!selectedItem || !isSuccess) return;
    const item = results.results.find(res => res.id === selectedItem);
    item && onSelectItem(item);
  }, [selectedItem]);

  return (
    <SearchResults
      results={results}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleSearch={handleSearch}
      query={rawSearchQuery}
      setQuery={setRawSearchQuery}
      setSelectedItem={setSelectedItem}
      category={category}
    />
  )
}

type Props = {
  setModalVisible: (prev: boolean) => void;
  onSelectItem: (id: AutoPopulateListItem) => void;
  category?: Categories;
}

const AutoPopulateItemModal = ({onSelectItem, category, setModalVisible}: Props) => {
  
  const onSelectItemLocal = (item: AutoPopulateListItem) => {
    onSelectItem(item);
    setModalVisible(false);
  }
  
  return (
    <Modal
      setModalVisible={setModalVisible}
      controls
      modalTitle="Search"
      removePadding
      scroll
      children={(() => {
        if (category === 'anime' || category === 'movies' || category === 'tvshows') return <SearchTMDB category={category} onSelectItem={onSelectItemLocal} />;
        else if (category === 'manga') return <SearchManga category={category} onSelectItem={onSelectItemLocal} />;
        else if (category === 'books') return <SearchBooks category={category} onSelectItem={onSelectItemLocal} />;
        else if (category === 'music') return <SearchMusic category={category} onSelectItem={onSelectItemLocal} />;
        else return null
      })()}
    />
  )
}

type ResultsProps<T> = {
  query: string;
  setQuery: (value: string) => void;
  handleSearch: () => void;
  results: MovieResults | AutoPopulateListItemResults | undefined;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  setSelectedItem: (prev: string) => void;
  category: Categories;
  filters?: {value: string, label: string}[];
  currentFilter?: T | null;
  setCurrentFilter?: (prev: T) => void;
};

const SearchResults = <T, >({query, setQuery, handleSearch, isLoading, isSuccess, isError, results, setSelectedItem, category, filters, currentFilter, setCurrentFilter}: ResultsProps<T>) => {
  
  const attribution = searchAttributions[category];
  
  return (
    <div className="item-list no-gap">
      <div className="bottom-border padding remove-padding-top item-list">
        <SearchBar autoFocus classNames={'full-width'} searchQuery={query} setSearchQuery={setQuery} onSubmit={handleSearch} />
        {filters && setCurrentFilter && 
          <FilterButtons
            filters={filters} 
            onClick={(val) => setCurrentFilter(val as T)} 
            selected={currentFilter as string || null} 
            preventDeselect
            removePadding
          />
        }
        <a href={attribution.link} target={"_blank"} rel={'noopener'} className="text">
          <p className="subtitle-medium secondary indent">Provided by {attribution.title}</p>
        </a>
      </div>
      {isLoading
        ? 
          <div className="item-row padding center-vertical">
            <LoadingIcon />
          </div>
        : isSuccess && results &&
          <div className="item-list">
            {results.results.map(movie =>
              <button key={movie.id} className="empty item-row large padding bottom-border" onClick={() => setSelectedItem(movie.id)}>
                <div className="thumb">
                  <img src={movie.image || fallbackImage} />
                </div>
                <p className="heading-small">{movie.title}</p>
              </button>
            )}
            {results.results.length === 0 && <p className="body secondary center padding">No results.</p>}
          </div>
      }
      {!isSuccess && !isError && !isLoading && <p className="body secondary center padding">Nothing to show.</p>}
      {isError && <p className="body secondary center padding">Something went wrong.</p>}
    </div>
  )
}

export default AutoPopulateItemModal;