import { useGetUserAutoPosts, useGetUserPrivatePosts } from "../../api/data-access";
import LoadingIcon from "../../components/shared/LoadingIcon";
import FeedCard from "../../components/shared/cards/FeedCard";

const PersonalTab = () => {
  
  const {data, isSuccess} = useGetUserPrivatePosts();
  const {data: auto, isSuccess: isAutoSuccess} = useGetUserAutoPosts();
  
  if (!isSuccess || !isAutoSuccess) return (
    <div className="item-row center-horizontal padding">
      <LoadingIcon />
    </div>
  )
  return (
    <section data-padding-small={true}>
      <div className="item-list large">
        {auto.length > 0 &&
          <div className="item-list">
            <p className="indent subtitle-large">Auto-Generated</p>
            {auto.map((l) => 
              <FeedCard key={l._id} post={l} small />
            )}
          </div>
        }
        <div className="item-list">
          <p className="indent subtitle-large">Your Personal Collections and Lists</p>
          {data.map((l) => 
            <FeedCard key={l._id} post={l} small returnRecentInSmartSet />
          )}
        </div>
        {data.length === 0 && 
          <p className="subtitle-large secondary center">
            Nothing to show
          </p>
        }
      </div>
    </section>
  )
}
export default PersonalTab;