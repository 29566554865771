import { useGetUserDraftPosts } from "../../api/data-access";
import LoadingIcon from "../../components/shared/LoadingIcon";
import FeedCard from "../../components/shared/cards/FeedCard";

const DraftsTab = () => {
  
  const {data, isSuccess} = useGetUserDraftPosts();
  
  if (!isSuccess) return (
    <div className="item-row center-horizontal padding">
      <LoadingIcon />
    </div>
  )
  return (
    <section data-padding-small={true}>
      <div className="item-list">
        {data.map((l) => 
          <FeedCard key={l._id} post={l} small link={l.type === 'list' ? `/creator/${l._id}` : `/collection/${l._id}`} returnRecentInSmartSet />
        )}
      </div>
      {data.length === 0 && 
        <p className="subtitle-large secondary center">
          Nothing to show
        </p>
      }
    </section>
  )
}
export default DraftsTab;