import Modal from "../../Modal";

type Props = {
  setModalVisible: (prev: boolean) => void;
  children?: React.ReactNode;
  closeText?: string
}

const LightboxModal = ({setModalVisible, children, closeText}: Props) => {
  return (
    <Modal 
      fullscreen
      setModalVisible={setModalVisible}
      children={
        <div className="bottom-item-layout no-gap full-height min-height">
          <div className="padding-small min-height">
            {children}
          </div>
          <div className="item-list padding-small">
            <button className="secondary" onClick={() => setModalVisible(false)}>{closeText || "Close"}</button>
          </div>
        </div>
      }
    />
  )
}
export default LightboxModal;