import PageWrapper from "./PageWrapper";
import { Outlet } from "react-router-dom";
import useHideHeader from "../hooks/useHideHeader";
import TabBar from "../components/navigation/TabBar";
import useCurrentUser from "../hooks/useCurrentUser";
import LoadingContainer from "../components/shared/LoadingContainer";

const tabs = [
  {title: "Public", link:'public'},
  {title: "Personal", link:'personal'},
  {title: "Drafts", link:'drafts'},
  {title: "Likes", link:'likes'}
];

const UserLists = () => {
  
  useHideHeader();
  const {user, isSuccess} = useCurrentUser();
  
  if (!isSuccess) return <LoadingContainer />;
  return (
    <PageWrapper padding={false}>
      <div data-padding={true} className="item-list">
        <h1 className="heading-medium weight-light">
          Your Lists
        </h1>
        <div className="item-row">
          <p className="subtitle-medium secondary">{`${user.listCount} List${user.listCount === 1 ? '' : 's'}`}</p>
          <p className="subtitle-medium secondary">{`${user.collectionCount} Collection${user.collectionCount === 1 ? '' : 's'}`}</p>
        </div>
      </div>
      <TabBar tabData={tabs} sticky />
      <Outlet />
    </PageWrapper>
  )
};

export default UserLists;