import ReactDOM from 'react-dom';
import ScrollContainer from './shared/ScrollContainer';
import { CloseIcon } from '../assets/icons';
import { useEffect } from 'react';
import LoadingIcon from './shared/LoadingIcon';

type ModalProps = {
  children: React.ReactNode;
  classNames?: string;
  bottomPane?: boolean;
  fullscreen?: boolean;
  scroll?: boolean;
  fixedBottomItem?: boolean;
  fixedBottomItemChildren?: React.ReactNode;
  controls?: boolean;
  modalTitle?: string;
  setModalVisible?: (prev: boolean) => void;
  removePadding?: boolean;
  fullHeightBottomPane?: boolean;
  isLoading?: boolean;
}

const Modal = ({
  children, 
  classNames,
  bottomPane, 
  fullscreen, 
  scroll, 
  fixedBottomItem, 
  setModalVisible, 
  controls, 
  modalTitle, 
  fixedBottomItemChildren, 
  removePadding, 
  fullHeightBottomPane,
  isLoading
}: ModalProps) => {
  
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.removeProperty('overflow');
    }
  },[]);
  
  return ReactDOM.createPortal(
    <div 
      className='modal-container' 
      data-bottom-pane={bottomPane}
      data-full-height-bottom-pane={fullHeightBottomPane}
      data-fullscreen={fullscreen} 
      data-fixed-bottom-item={fixedBottomItem}
      data-remove-padding={removePadding}
      onClick={(e) => e.target === e.currentTarget && setModalVisible && !isLoading && setModalVisible(false)}
    >
      <div data-scroll={scroll} className={"modal " + (classNames || "")}>
        {isLoading ?
          <div className="item-row center-horizontal padding">
            <LoadingIcon small />
            <p className="body">Loading...</p>
          </div> 
          : <>
            {controls ? 
              <ModalInnerWithControls
                title={modalTitle} 
                setModalVisible={setModalVisible} 
                children={children} 
                fixedBottomItem={fixedBottomItem} 
                fixedBottomItemChildren={fixedBottomItemChildren}
                bottomPane={bottomPane}
                removePadding={removePadding}
              /> 
              : children
            }
          </>
        }
      </div>
    </div>,
    document.querySelector('#modal-root')!
  );
}

type InnerProps = {
  children: React.ReactNode, 
  title?: string, 
  setModalVisible?: (prev: boolean) => void,
  fixedBottomItem?: boolean;
  fixedBottomItemChildren?: React.ReactNode;
  bottomPane?: boolean;
  removePadding?: boolean;
}

const ModalInnerWithControls = ({children, setModalVisible, title, fixedBottomItem, fixedBottomItemChildren, bottomPane, removePadding}: InnerProps) => {
  
  const paddingClass = bottomPane ? 'padding-small' : 'padding';
  
  return (
    <div className={fixedBottomItem ? 'bottom-item-layout no-gap' : 'item-list'}>
      <div className={`item-list ${fixedBottomItem && children ? 'bottom-border border-secondary' : '' }`}>
        {title && setModalVisible &&
          <div className={`right-item-layout ${paddingClass} remove-padding-bottom center-horizontal`}>
            <h1 className={bottomPane ? 'heading-small' : "heading-medium"}>{title}</h1>
            <button className='icon xs' onClick={() => setModalVisible(false)}>
              <CloseIcon />
            </button>
          </div>
        }
        {children && 
          <div className={removePadding ? 'full-height' : 'full-height ' + paddingClass}>
            {children}
          </div>
        }
      </div>
      {fixedBottomItemChildren}
    </div>
  )
}

export default Modal;