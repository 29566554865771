import PageWrapper from "./PageWrapper";
import { ArrowHeadLeftIcon, ArrowHeadRightIcon, TrendingIcon } from "../assets/icons";
import React, { useEffect, useRef, useState } from "react";
import Modal from "../components/Modal";
import LoadingIcon from "../components/shared/LoadingIcon";
import { categories } from "../assets/categories";
import { useGetFollowingPostFeed, useGetPostFeed, useGetPostFeedOpen } from "../api/data-access";
import FeedCard from "../components/shared/cards/FeedCard";
import { useWindowVirtualizer } from "@tanstack/react-virtual";
import { InfiniteData, UseInfiniteQueryResult } from "@tanstack/react-query";
import { PagedResponse, PopulatedListPostType } from "../types/SharedTypes";
import TabBar from "../components/navigation/TabBar";

const Dashboard = ({auth}: {auth?: boolean}) => {
  
  const [feed, setFeed] = useState<'all' | 'following'>('all');
  
  if (auth === false) return (
    <PageWrapper>
      <InfinitePostFeed queryHook={useGetPostFeedOpen} />
    </PageWrapper>
  )
  return (
    <PageWrapper>
      <div className="padding-small">
        <TabBar unstyled tabData={[
          {title: 'All', onClick: () => setFeed('all')},
          {title: 'Following', onClick: () => setFeed('following')}
        ]} />
      </div>
      {feed === 'following' 
        ? <InfinitePostFeed queryHook={useGetFollowingPostFeed} />
        : <InfinitePostFeed queryHook={useGetPostFeed} />
      }
    </PageWrapper>
  )
}

type InfinitePostFeedProps = {
  queryHook: (category?: string, enabled?: boolean) => UseInfiniteQueryResult<InfiniteData<PagedResponse<PopulatedListPostType>, unknown>, Error>;
}

const InfinitePostFeed = ({queryHook}: InfinitePostFeedProps) => {
  
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  
  const {data: posts, isPending, isError, isSuccess, fetchNextPage, hasNextPage, isFetchingNextPage} = queryHook(selectedFilter || undefined);
  
  const allData = posts ? posts.pages.flatMap((d) => d.data) : [];

  const listRef = useRef<HTMLDivElement | null>(null);
  const virtualizer = useWindowVirtualizer({
    count: hasNextPage ? allData.length + 1 : allData.length,
    estimateSize: () => 120,
    overscan: 1,
    scrollMargin: listRef.current?.offsetTop ?? 0,
  });
  
  useEffect(() => {
    const [lastItem] = [...virtualizer.getVirtualItems()].reverse();

    if (!lastItem) return;

    if (
      lastItem.index >= allData.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [
    hasNextPage,
    fetchNextPage,
    allData.length,
    isFetchingNextPage,
    virtualizer.getVirtualItems(),
  ]);
  
  const virtualItems = virtualizer.getVirtualItems();
  
  if (isError) return <p>Error</p>;
  return (
    <>
      {filterModalVisible && 
        <FilterModal selected={selectedFilter} setSelected={(val) => setSelectedFilter(val)} setModalVisible={setFilterModalVisible}  />
      }
      <div className="item-list">
        <div className="right-item-layout full-height center-horizontal no-gap align-content-start">
          <FilterButtons filters={categories} onClick={(val) => setSelectedFilter(val)} selected={selectedFilter} escapePadding gradientOverflow />
          <div className="height-80 item-row small">
            <div className="full-height left-border border-secondary" />
            <button className="icon xs" onClick={() => setFilterModalVisible(true)}>
              <ArrowHeadRightIcon />
            </button>
          </div>
        </div>
        {isPending && 
          <div className="item-row center-horizontal padding">
            <LoadingIcon />
          </div>
        }
        {isSuccess && 
          <section className="item-list large">
            {posts.pages.length === 0 && <p className="subtitle-large secondary center">Nothing to show.</p>}
            <div ref={listRef}>
              <div
                className="card-feed"
                style={{
                  height: `${virtualizer.getTotalSize()}px`,
                  width: '100%',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    transform: `translateY(${(virtualItems[0]?.start ?? 0) - virtualizer.options.scrollMargin}px)`,
                  }}
                >
                  {virtualItems.map((item) => {
                    const isLoaderRow = item.index > allData.length - 1;
                    const post = allData[item.index];
                    return (
                      <div
                        key={item.index}
                        data-index={item.index}
                        ref={virtualizer.measureElement}
                      >
                        {isLoaderRow && hasNextPage 
                          ?
                            <div className="item-row center-horizontal">
                              <LoadingIcon />
                            </div> 
                          :
                            <FeedCard
                              key={post._id}
                              post={post}
                              // badgeBanner={<TrendingBannerBadge/>}
                            />
                        }
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </section>
        }
      </div>
    </>
  )
}

const TrendingBannerBadge = () => {
  return (
    <div className="item-row center-vertical">
      <div className="icon xsmall secondary">
        <TrendingIcon />
      </div>
      <p className="subtitle-medium secondary">Trending</p>
    </div>
  )
}

type Filter = {
  label: string;
  value: string;
}

type FilterButtonProps = {
  filters: Filter[];
  selected: string | null;
  escapePadding?: boolean;
  removePadding?: boolean;
  gradientOverflow?: boolean;
  wrap?: boolean;
} & ({
  onClick: (val: string) => void;
  preventDeselect: true;
} | {
  onClick: (val: string | null) => void;
  preventDeselect?: never;
})

export const FilterButtons = ({filters, onClick, selected, escapePadding, removePadding, gradientOverflow, wrap, preventDeselect}: FilterButtonProps) => {
  
  const scrollRef = useRef<HTMLDivElement>(null);
  
  const handleClick = (filter: Filter) => {
    preventDeselect
      ? onClick(filter.value) 
      : onClick(filter.value !== selected ? filter.value : null);
      
    if (!scrollRef.current) return;
    scrollRef.current.scrollTo(0,0);
  }
  
  return (
    <div className="filter-buttons item-row" data-wrap={wrap} data-escape={escapePadding} data-no-padding={removePadding} data-gradient-overflow={gradientOverflow}>
      <div ref={scrollRef} className="filter-buttons-row item-row">
        {filters.map(filter =>
          <button
            key={filter.value}
            className="filter secondary" 
            aria-selected={filter.value === selected} 
            onClick={() => handleClick(filter)}
          >
            {filter.label}
          </button>
        )}
        {gradientOverflow && <div className="gradient-overflow" />}
      </div>
    </div>
  )
}

type FilterModalProps = {
  selected: string | null;
  setSelected: (val: string | null) => void;
  setModalVisible: (prev: boolean) => void;
}

export const FilterModal = ({selected, setSelected, setModalVisible}: FilterModalProps) => {
  
  return (
    <Modal fullscreen>
      <section className="item-list xl padding-small">
        <div className="item-row small">
          <button className="icon xs" onClick={() => setModalVisible(false)}>
            <ArrowHeadLeftIcon />
          </button>
          <p className="heading-small weight-bold">All Filters</p>
        </div>
        <div className="item-list large">
          <p className="subtitle-large indent">Categories</p>
          <FilterButtons filters={categories} onClick={setSelected} selected={selected} wrap />
        </div>
      </section>
    </Modal>
  )
}

export default Dashboard;