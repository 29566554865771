import { CollectionPeriods } from "../types/SharedTypes"

export const localPrefKeys = {
  disableThemeInEditor: 'disabled_theme_in_editor',
  forceDarkTheme: 'force_dark_theme'
}
export const localStorageKeys = {
  listCreatorDraft: 'list_creator_draft',
  localPreferences: 'local_prefs'
}

export const searchAttributions: {[key: string]: {title: string, link: string}} = {
  manga: {
    title: 'Mangadex',
    link: "https://mangadex.org"
  },
  movies: {
    title: 'TMDB',
    link: "https://www.themoviedb.org/"
  },
  tvshows: {
    title: 'TMDB',
    link: "https://www.themoviedb.org/"
  },
  anime: {
    title: 'TMDB',
    link: "https://www.themoviedb.org/"
  },
  books: {
    title: "Google Books",
    link: "https://books.google.ca/"
  },
  music: {
    title: "Spotify",
    link: "https://spotify.com"
  }
}

export const smartPeriodSingular = (period?: CollectionPeriods, cap?: boolean) => {
  if (period === 'weekly') return cap ? 'Week' : 'week';
  else if (period === 'monthly') return cap ? 'Month' : 'month';
  else if (period === 'seasonal') return cap ? 'Season' : 'season';
  else if (period === 'yearly') return cap ? 'Year' : 'year';
  else return '';
}